define("front/templates/components/screen-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q5oRezaX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"icon\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/components/screen-loader.hbs"
    }
  });

  _exports.default = _default;
});