define("front/routes/summerjobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    queryParams: {
      search: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var search = _ref.search;
      search = search && search.length > 2 ? search : undefined;

      var _controller = this.controllerFor('summerjobs');

      return Ember.RSVP.hash({
        jobs: this.infinity.model('job', {
          perPage: 30,
          startingPage: 1,
          perPageParam: 'size',
          jobtypeids: 3,
          zipids: _controller.get('zipids') ? _controller.get('zipids').join() : undefined,
          search: search
        }),
        categories: this.store.findAll('category'),
        areas: this.store.findAll('area')
      }).then(function (res) {
        var applicationCntrl = _this.controllerFor('application');

        applicationCntrl.set('hideLoader');
        return res;
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        var applicationCntrl = this.controllerFor('application');
        applicationCntrl.set('hideLoader', true);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});