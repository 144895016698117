define("front/routes/embed", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.query('job', {
        domain: params.domain,
        perPage: 30,
        startingPage: 1
      });
    },
    afterModel: function afterModel() {
      window.parent.postMessage(["setHeight", (0, _jquery.default)('#embed').height()], "*");
    }
  });

  _exports.default = _default;
});