define("front/templates/components/modals/profile-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "INJU3Iwj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup termsModal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"logo\"],[14,\"src\",\"/img/alfred-no-text.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n\\n      \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.profile_delete.title\"],null]],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.profile_delete.p\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-brd-gray\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\\n\"],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/modals/profile-delete.hbs"
    }
  });

  _exports.default = _default;
});