define("front/components/notification-container", ["exports", "ember-cli-notifications/components/notification-container"], function (_exports, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notificationContainer.default.extend({
    classNames: ['notifications-alerts'],
    computedPosition: 'dark-top'
  });

  _exports.default = _default;
});