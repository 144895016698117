define("front/templates/profile/video/done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WMsEo7Y8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"h1\"],[12],[2,\"Prufuviðtali lokið\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"h1\"],[12],[2,\"Viðtalinu er nú lokið\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Takk \"],[1,[34,3]],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Þú hefur nú lokið við prufuviðtalið.\"],[10,\"br\"],[12],[13],[2,\"Svörin þín úr prufuviðtalinu voru ekki tekin upp og þar af leiðandi hvergi vistuð.\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Vídeóviðtalinu er nú lokið.\"],[10,\"br\"],[12],[13],[2,\"Mikilvægt er að slökkva ekki á þessum glugga fyrr en búið er að hlaða vídeóviðtalinu upp að fullu.\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"video/upload-progress\",[],[[\"@progress\"],[[34,0,[\"progress\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"buttons \",[30,[36,4],[[30,[36,5],[[35,0,[\"progress\"]],100],null],\"disabled\"],null]]]],[12],[2,\"\\n\"],[2,\"    \"],[10,\"a\"],[15,6,[31,[\"/user/inbox\",[30,[36,4],[[35,1],\"?id=\"],null],[30,[36,4],[[35,1],[35,6,[\"id\"]]],null]]]],[14,0,\"btn btn-green upper\"],[12],[2,\"Loka glugga\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"videoManager\",\"isDemo\",\"if\",\"name\",\"unless\",\"gte\",\"model\"]}",
    "meta": {
      "moduleName": "front/templates/profile/video/done.hbs"
    }
  });

  _exports.default = _default;
});