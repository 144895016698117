define("front/templates/profile/video/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YkD6E+8a",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[2,\"Jæja… nú fer þetta að byrja\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Spurningarnar munu birtast hér.\"],[10,\"br\"],[12],[13],[2,\"Ýttu á “Næsta” til að fá næstu spurningu ef þú hefur lokið við að svara áður en niðurtalningu lýkur.\"],[10,\"br\"],[12],[13],[2,\"Mundu að þú getur ekki stoppað viðtalið eftir að það hefst.\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"video info\"],[12],[2,\"\\n\\n    \"],[8,\"video/video-stream\",[],[[\"@videoManager\",\"@clean\"],[[34,0],true]],null],[2,\"\\n\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-brd-gray upper\"]],[[\"@route\"],[\"profile.video.test\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"string.back\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,3],[[30,[36,2],[[35,0,[\"selectedVideo\"]],[35,0,[\"selectedAudio\"]]],null],\"disabled\"],null]],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-green upper\"]],[[\"@route\"],[\"profile.video.interview\"]],[[\"default\"],[{\"statements\":[[2,\"Byrja\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"videoManager\",\"t\",\"and\",\"unless\"]}",
    "meta": {
      "moduleName": "front/templates/profile/video/info.hbs"
    }
  });

  _exports.default = _default;
});