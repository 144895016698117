define("front/helpers/get-proper-url", ["exports", "alfred-company-profile/helpers/get-proper-url"], function (_exports, _getProperUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getProperUrl.default;
    }
  });
  Object.defineProperty(_exports, "getProperUrl", {
    enumerable: true,
    get: function get() {
      return _getProperUrl.getProperUrl;
    }
  });
});