define("front/templates/user/favorites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1jDt83c0",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"flex-container\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"jobs\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-jobs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"group-title\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[1,[30,[36,1],[\"menu.favorite-jobs\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"react-job-card/react-job-card\",[],[[\"@jobs\"],[[32,1,[\"jobs\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ui/empty-message\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"job.favorite.empty-text\"],null]],[2,\"\\n      \"],[8,\"layout/space\",[],[[\"@top\"],[\"5\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"job.favorite.empty-text-link\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,3],[[32,1,[\"jobs\",\"length\"]],29],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"infinity-loader\",[],[[\"@infinityModel\",\"@triggerOffset\"],[[34,0,[\"jobs\"]],300]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"dot-loader\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"if\",\"gt\"]}",
    "meta": {
      "moduleName": "front/templates/user/favorites.hbs"
    }
  });

  _exports.default = _default;
});