define("front/models/profile", ["exports", "@ember-data/model", "ember-api-actions", "moment"], function (_exports, _model, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    emailVerified: (0, _model.attr)('boolean'),
    msisdn: (0, _model.attr)('string'),
    // only as login, for display always use :phonenumber
    phonenumber: (0, _model.attr)('string'),
    phoneVerified: (0, _model.attr)('boolean'),
    birthday: (0, _model.attr)('string'),
    initials: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    gender: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    language: (0, _model.attr)('string'),
    profileType: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    machineTranslate: (0, _model.attr)('boolean'),
    activeApplications: (0, _model.attr)('number'),
    attachmentcount: (0, _model.attr)('number'),
    notes: (0, _model.attr)('number'),
    percentcompleted: (0, _model.attr)('number'),
    minimumprofilefilling: (0, _model.attr)('number'),
    acceptedTerms: (0, _model.attr)('boolean'),
    notifications: (0, _model.attr)(),
    cookies: (0, _model.attr)(),
    // HAS NO ID so it's better to not use model
    profileStatus: (0, _model.belongsTo)('profile-status'),
    attachments: (0, _model.hasMany)('attachment'),
    experiences: (0, _model.hasMany)('experience'),
    educations: (0, _model.hasMany)('education'),
    languages: (0, _model.hasMany)('languagelevel'),
    profileRecommendations: (0, _model.hasMany)('recommendation'),
    links: (0, _model.hasMany)('link'),
    skills: (0, _model.hasMany)('skill'),
    verification: (0, _model.attr)(),
    // e-sign verification
    saveCookies: (0, _emberApiActions.memberAction)({
      path: 'cookies',
      type: 'patch'
    }),
    newexperience: (0, _emberApiActions.memberAction)({
      path: 'experiences',
      type: 'post'
    }),
    neweducation: (0, _emberApiActions.memberAction)({
      path: 'educations',
      type: 'post'
    }),
    educationdegrees: (0, _emberApiActions.collectionAction)({
      path: 'educationdegrees',
      type: 'get'
    }),
    newlanguagelevel: (0, _emberApiActions.memberAction)({
      path: 'languagelevels',
      type: 'post'
    }),
    deleteProfile: (0, _emberApiActions.memberAction)({
      path: 'delete',
      type: 'get'
    }),
    newlink: (0, _emberApiActions.memberAction)({
      path: 'links',
      type: 'post'
    }),
    newrecommendation: (0, _emberApiActions.memberAction)({
      path: 'profilerecommendations',
      type: 'post'
    }),
    updateNotifications: (0, _emberApiActions.memberAction)({
      path: 'notifications',
      type: 'patch'
    }),
    pdf: (0, _emberApiActions.memberAction)({
      path: 'pdf',
      type: 'get'
    }),
    firstName: Ember.computed('name', function () {
      if (!this.name) {
        return;
      }

      return this.name.split(' ')[0];
    }),
    age: Ember.computed('birthday', function () {
      return this.birthday ? (0, _moment.default)().diff((0, _moment.default)(this.birthday), 'years') : '';
    }),
    birthday_format: Ember.computed('birthday', function () {
      return "".concat((0, _moment.default)(this.birthday).format('MM/DD/YYYY'));
    }),
    thumb_initials: Ember.computed('image', 'initials', function () {
      if (!this.image) {
        return this.initials;
      }
    }),
    skill_tags: Ember.computed('skills', function () {
      if (this.skills) {
        return this.skills.split(', ');
      }
    }),
    isActive: Ember.computed('status', function () {
      if (this.status) {
        return this.status == 'ACTIVE';
      }

      return true;
    })
  });

  _exports.default = _default;
});