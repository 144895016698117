define("front/templates/components/companies/box-vi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BMt6t0QI",
    "block": "{\"symbols\":[\"@darker\"],\"statements\":[[10,\"div\"],[14,1,\"videovidtol\"],[15,0,[31,[\"container paddbox \",[30,[36,0],[[32,1],\"darker\"],null]]]],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"companies.vi.h2\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"companies.vi.p1\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"top-space\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"companies.vi.p2\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"vi-features\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/companies/box-vi.hbs"
    }
  });

  _exports.default = _default;
});