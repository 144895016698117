define("front/components/apply/application-questions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['application-questions'],
    questionIndex: Ember.computed('activeQuestion', function () {
      return this.activeQuestion - 1;
    }),
    currentQuestion: Ember.computed('activeQuestion', function () {
      if (this.activeQuestion) {
        return this.questions.objectAt(this.activeQuestion - 1);
      }
    }),
    hasAnswered: Ember.computed('activeQuestion', 'currentQuestion.{answer,date,optionId,optionIds.[]}', function () {
      return !this.activeQuestion || this.get('currentQuestion.optionIds.length') || this.get('currentQuestion.answer') || this.get('currentQuestion.date') || this.get('currentQuestion.optionId');
    }),
    actions: {
      nextQuestion: function nextQuestion() {
        if (this.hasAnswered) {
          this.set('activeQuestion', this.activeQuestion + 1);
        }
      },
      close: function close() {
        this.close();
      },
      selectOption: function selectOption(question, option) {
        if (question.optionIds.indexOf(option) >= 0 || question.get('optionId.id') == option.id) {
          question.optionIds.removeObject(option);
          question.set('optionId');
        } else {
          var type = question.get('questionType.type');

          if (type == 'YES_NO' || type == 'SINGLE_OPTION') {
            question.set('optionIds', Ember.A());
            question.set('optionId', option);
          }

          question.optionIds.pushObject(option);
        }
      },
      setDate: function setDate(question, date) {
        if (date) {
          question.set('date', (0, _moment.default)(date).format('YYYY-MM-DD'));
        }
      },
      applyProfile: function applyProfile() {
        this.applyProfile();
      }
    }
  });

  _exports.default = _default;
});