define("front/models/property", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    completed: (0, _model.attr)('boolean'),
    percentage: (0, _model.attr)('number'),
    // property: attr('string'),
    required: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});