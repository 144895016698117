define("front/models/question", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    question: (0, _model.attr)('string'),
    questionType: (0, _model.belongsTo)('questiontype'),
    options: (0, _model.hasMany)('questionoption'),
    optionIds: (0, _model.hasMany)('questionoption', {
      defaultValue: []
    }),
    answer: (0, _model.attr)('string'),
    optionId: (0, _model.belongsTo)('questionoption'),
    date: (0, _model.attr)('string')
  });

  _exports.default = _default;
});