define("front/components/detail/more-jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="detail-more-jobs">
    <div class="detail-more-jobs--title">{{@title}}</div>
    <Jobs::Grid>
      {{#each @jobs as |job|}}
        <Jobs::JobItem @job={{job}}
          @category={{@category.id}}
          @track={{@track}} />
      {{/each}}
    </Jobs::Grid>
  </div>
  
  */
  {
    "id": "38uBpw3i",
    "block": "{\"symbols\":[\"job\",\"@category\",\"@track\",\"@title\",\"@jobs\"],\"statements\":[[10,\"div\"],[14,0,\"detail-more-jobs\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"detail-more-jobs--title\"],[12],[1,[32,4]],[13],[2,\"\\n  \"],[8,\"jobs/grid\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"jobs/job-item\",[],[[\"@job\",\"@category\",\"@track\"],[[32,1],[32,2,[\"id\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "front/components/detail/more-jobs.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});