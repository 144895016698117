define("front/templates/components/modals/job-apply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kNDO+YB6",
    "block": "{\"symbols\":[\"@job\",\"@reloadJob\",\"@processing\",\"@success\"],\"statements\":[[2,\"  \"],[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[32,0,[\"toggle\"]],\"modal-popup job-apply\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,9],[[35,8,[\"applyType\"]],\"PROFILE\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],[[32,0,[\"profile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],[[32,0,[\"processing\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"apply/application-success\",[],[[\"@processing\",\"@success\",\"@job\",\"@profile\",\"@application\",\"@close\"],[[32,3],[32,4],[32,1],[32,0,[\"profile\"]],[34,6],[32,0,[\"toggle\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"apply/apply-profile\",[],[[\"@profile\",\"@updating\",\"@job\",\"@completePercentage\",\"@close\",\"@applyProfile\",\"@questions\",\"@activeQuestion\"],[[32,0,[\"profile\"]],[34,1],[32,1],[34,2],[32,0,[\"toggle\"]],[30,[36,3],[[32,0],\"applyProfile\"],null],[34,4],[34,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,\"login/index\",[],[[\"@toggle\",\"@afterLogin\",\"@title\",\"@subtitle\"],[[32,0,[\"toggle\"]],[32,2],[30,[36,0],[\"job.apply.login_title\"],null],[30,[36,0],[\"job.apply.login_subtitle\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"apply/application-external\",[],[[\"@job\",\"@close\"],[[32,1],[32,0,[\"toggle\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"updating\",\"completePercentage\",\"action\",\"questions\",\"activeQuestion\",\"application\",\"if\",\"job\",\"eq\"]}",
    "meta": {
      "moduleName": "front/templates/components/modals/job-apply.hbs"
    }
  });

  _exports.default = _default;
});