define("front/templates/components/apply/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2Zauqlo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"status \",[34,5]]]],[15,5,[30,[36,7],[[30,[36,6],[\"width:\",[35,2],\"%;\"],null]],null]],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[35,2],100],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,2]],[2,\"%\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\"/\"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentNumber\",\"total\",\"progress\",\"percentage\",\"if\",\"statusColor\",\"concat\",\"html-safe\",\"eq\",\"unless\"]}",
    "meta": {
      "moduleName": "front/templates/components/apply/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});