define("front/routes/jobs", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var index = _environment.default.REGION.routes.index;

  var _default = Ember.Route.extend({
    queryParams: {
      cat: {
        refreshModel: false
      }
    },
    session: Ember.inject.service(),
    reactPortal: Ember.inject.service(),
    setupController: function setupController(controller) {
      controller.set('title', index[1]);
    }
  });

  _exports.default = _default;
});