define("front/routes/about", ["exports", "front/config/environment", "front/mixins/reset-scroll"], function (_exports, _environment, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var routes = _environment.default.REGION.routes;

  var _default = Ember.Route.extend(_resetScroll.default, {
    intl: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (routes.about[1] !== 'PRISMIC') {
        controller.set('title', routes.about[1]);
      }
    }
  });

  _exports.default = _default;
});