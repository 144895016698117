define("front/controllers/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loginManager: Ember.inject.service(),
    actions: {
      logout: function logout() {
        var _this = this;

        this.loginManager.logout(function () {
          _this.transitionToRoute('index');
        });
      }
    }
  });

  _exports.default = _default;
});