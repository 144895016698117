define("front/templates/embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G6EWS7g9",
    "block": "{\"symbols\":[\"job\"],\"statements\":[[10,\"div\"],[14,1,\"embed\"],[12],[2,\"\\n\\n\"],[6,[37,5],[[35,2,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[10,\"main\"],[12],[2,\"\\n\\n      \"],[10,\"h1\"],[12],[2,\"Störf í boði\"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"jobs\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"a\"],[14,0,\"job\"],[15,6,[31,[[30,[36,1],[\"web\"],null],\"/starf/\",[32,1,[\"id\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n            \"],[10,\"h3\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,[32,1,[\"description\"]]],[13],[2,\"\\n\\n            \"],[10,\"strong\"],[12],[2,\"Nánari upplýsingar\"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"empty\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"category.empty\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"get-link\",\"model\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/embed.hbs"
    }
  });

  _exports.default = _default;
});