define("front/components/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['search-bar'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('query', this.search);
    },
    actions: {
      search: function search() {
        this.set('loading', true);
        this.action(this.query);
      },
      clear: function clear() {
        this.action();
      }
    }
  });

  _exports.default = _default;
});