define("front/adapters/application", ["exports", "@ember-data/adapter/rest", "ember-local-storage", "front/config/environment"], function (_exports, _rest, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$REGION = _environment.default.REGION,
      locale = _config$REGION.locale,
      resturl = _config$REGION.app.resturl,
      NAMESPACE = _environment.default.NAMESPACE;

  var _default = _rest.default.extend({
    loginManager: Ember.inject.service(),
    host: resturl,
    namespace: NAMESPACE,
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    headers: Ember.computed('storage.lang', function () {
      return {
        'Accept-Language': this.storage.get('lang') || locale
      };
    }),
    ajaxOptions: function ajaxOptions(url, type, options) {
      var methodType = type === 'PUT' ? 'PATCH' : type;
      return this._super(url, methodType, options);
    },
    handleResponse: function handleResponse(status) {
      if (status === 401) {
        this.loginManager.logout();
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});