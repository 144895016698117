define("front/components/apply/update-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['update-profile'],
    step: 1,
    observeProgress: Ember.observer('emailValid', 'profile.{name,phonenumber,image,body,experiences.[],educations.[],languages.[]}', function () {
      this.updateProperty('EMAIL', this.emailValid);
      this.updateProperty('NAME', !!this.profile.name);
      this.updateProperty('PHONE', this.profile.phonenumber && this.profile.phonenumber.length > 5);
      this.updateProperty('BODY', !!this.profile.body);
      this.updateProperty('EXPERIENCE', this.profile.experiences.length);
      this.updateProperty('EDUCATION', this.profile.educations.length);
      this.updateProperty('LANGUAGES', this.profile.languages.length);
    }),
    emailValid: Ember.computed('profile.email', function () {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.profile.email).toLowerCase());
    }),
    updateProperty: function updateProperty(property, completed) {
      if (this.get('profile.profileStatus.properties.length')) {
        var p = this.get('profile.profileStatus.properties').filter(function (p) {
          return p.id == property;
        })[0];

        if (!p) {
          return;
        }

        p.set('completed', completed);
      }
    },
    actions: {
      toggleUpdating: function toggleUpdating() {
        var _this = this;

        this.profile.save().then(function () {
          return _this.set('updating');
        }).catch(function () {
          return _this.notifications.error(_this.intl.t('string.general_error'));
        });
        this.set('step', 1);
      },
      cancelUpdating: function cancelUpdating() {
        this.set('updating');
      },
      setStep: function setStep(step) {
        var _this2 = this;

        this.set('step', step);
        this.profile.save().catch(function () {
          return _this2.notifications.error(_this2.intl.t('string.general_error'));
        });
      },
      didSelectFiles: function didSelectFiles(model, files) {
        var _this3 = this;

        if (files.length) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          this.set('uploadingPicture', true);
          reader.addEventListener('load', function () {
            model.set('image', reader.result);

            _this3.updateProperty('IMAGE', true);

            model.save().finally(function () {
              return _this3.set('uploadingPicture');
            });
          }, false);
        }
      },
      saveExperience: function saveExperience(experience) {
        var _this4 = this;

        this.profile.newexperience({
          experience: experience.serialize()
        }).then(function () {
          _this4.currentSession.load();

          _this4.set('newExperienceModal');
        });
      },
      saveEducation: function saveEducation(education) {
        var _this5 = this;

        this.profile.neweducation({
          education: education.serialize()
        }).then(function () {
          _this5.currentSession.load();

          _this5.set('newEducationModal');
        });
      },
      saveLanguage: function saveLanguage(language) {
        var _this6 = this;

        this.profile.newlanguagelevel({
          languagelevel: language.serialize()
        }).then(function () {
          _this6.currentSession.load();

          _this6.set('newLanguageModal');
        });
      },
      setContext: function setContext(context) {
        this.set('context', context);

        if (context == 'EXPERIENCE' && !this.get('profile.experiences.length')) {
          this.toggleProperty('newExperienceModal');
        }

        if (context == 'EDUCATION' && !this.get('profile.educations.length')) {
          this.toggleProperty('newEducationModal');
        }

        if (context == 'LANGUAGE' && !this.get('profile.languages.length')) {
          this.toggleProperty('newLanguageModal');
        }
      }
    }
  });

  _exports.default = _default;
});