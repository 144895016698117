define("front/templates/components/apply/apply-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "USCCF3WT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,8],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,10],[[30,[36,9],[[35,1,[\"profileStatus\",\"requiredPercentage\"]],[35,1,[\"profileStatus\",\"totalPercentage\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"apply/update-profile\",[],[[\"@updating\",\"@profile\",\"@progress\",\"@job\"],[[34,0],[34,1],[34,2],[34,4]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,8],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"apply/application-summary\",[],[[\"@profile\",\"@job\",\"@updating\",\"@requiresEsign\",\"@showEsign\",\"@applyProfile\",\"@close\"],[[34,1],[34,4],[34,0],[34,4,[\"verificationRequired\"]],[34,3],[30,[36,7],[[32,0],\"applyProfile\"],null],[30,[36,7],[[32,0],\"close\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"apply/application-questions\",[],[[\"@questions\",\"@profile\",\"@job\",\"@activeQuestion\",\"@applyProfile\",\"@close\"],[[34,5],[34,1],[34,4],[34,6],[30,[36,7],[[32,0],\"applyProfile\"],null],[30,[36,7],[[32,0],\"close\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"apply/e-sign\",[],[[\"@showEsign\",\"@job\",\"@profile\",\"@close\"],[[34,3],[34,4],[34,1],[32,0,[\"close\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"apply/update-profile\",[],[[\"@updating\",\"@profile\",\"@progress\"],[[34,0],[34,1],[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"updating\",\"profile\",\"completePercentage\",\"showEsign\",\"job\",\"questions\",\"activeQuestion\",\"action\",\"unless\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/components/apply/apply-profile.hbs"
    }
  });

  _exports.default = _default;
});