define("front/templates/components/box-companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "igas2/Si",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"fluid-container bg-2\"],[14,1,\"boxCompanies\"],[15,5,[30,[36,0],[\"alfrello.jpg\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container wrapbox\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-12 col-md-offset-12\"],[12],[2,\"\\n          \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"index.client.h2\"],null]],[13],[2,\"\\n\\n          \"],[10,\"p\"],[12],[1,[30,[36,1],[\"index.client.p\"],null]],[13],[2,\"\\n\\n          \"],[10,\"strong\"],[12],[1,[30,[36,1],[\"index.client.strong\"],null]],[13],[2,\"\\n\\n          \"],[1,[30,[36,1],[\"index.client.ul\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\\n          \"],[10,\"br\"],[12],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n            \"],[8,\"link-to\",[[24,0,\"btn btn-brd-dark upper\"]],[[\"@route\"],[\"companies\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"string.more\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,\"link-to\",[[24,0,\"btn btn-brd-dark upper\"]],[[\"@route\",\"@query\"],[\"companies\",[30,[36,3],null,[[\"a\"],[[35,2]]]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"index.client.link\"],null]]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n\\n          \"],[10,\"br\"],[12],[13],[2,\"\\n          \"],[10,\"br\"],[12],[13],[2,\"\\n          \"],[10,\"br\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"region-bg\",\"t\",\"register\",\"hash\"]}",
    "meta": {
      "moduleName": "front/templates/components/box-companies.hbs"
    }
  });

  _exports.default = _default;
});