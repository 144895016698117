define("front/components/apply/application-summary-skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="application-summary-skills">
    <div class="application-summary-skills--container">
      <div class="application-summary-skills--title">
        {{@job.brand.name}} {{t "components.job_apply.choose_skills.title"}}
      </div>
      <div class="flex-wrap">
        {{#each @job.skills as |skill|}}
          <Skills::Skill @skill={{skill}} @showCategoryTooltip={{true}} />
        {{/each}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "m93K/kjm",
    "block": "{\"symbols\":[\"skill\",\"@job\"],\"statements\":[[10,\"div\"],[14,0,\"application-summary-skills\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"application-summary-skills--container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"application-summary-skills--title\"],[12],[2,\"\\n      \"],[1,[32,2,[\"brand\",\"name\"]]],[2,\" \"],[1,[30,[36,0],[\"components.job_apply.choose_skills.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-wrap\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2,[\"skills\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"skills/skill\",[],[[\"@skill\",\"@showCategoryTooltip\"],[[32,1],true]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "front/components/apply/application-summary-skills.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});