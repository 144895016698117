define("front/components/application-message/message-attachment-meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="message-attachment-meta">
    <div class="message-attachment-meta--item">
      <span class="message-attachment-meta--icon date-icon"></span>
      {{moment-format @attachment.date "D. MMM YYYY"}}, {{moment-format @attachment.date "HH:mm"}} - {{moment-format @attachment.dateTo "HH:mm"}}
    </div>
    {{#unless @attachment.remoteInterview}}
      <div class="message-attachment-meta--item">
        <span class="message-attachment-meta--icon location-icon"></span>
        {{@attachment.address}}
      </div>
    {{else}}
      <div class="message-attachment-meta--item">
        <span class="message-attachment-meta--icon camera-icon"></span>
        {{t "message-attachment.remote-interview-meta"}}
      </div>
    {{/unless}}
  </div>
  
  */
  {
    "id": "FL2xahAQ",
    "block": "{\"symbols\":[\"@attachment\"],\"statements\":[[10,\"div\"],[14,0,\"message-attachment-meta\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"message-attachment-meta--item\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"message-attachment-meta--icon date-icon\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"date\"]],\"D. MMM YYYY\"],null]],[2,\", \"],[1,[30,[36,1],[[32,1,[\"date\"]],\"HH:mm\"],null]],[2,\" - \"],[1,[30,[36,1],[[32,1,[\"dateTo\"]],\"HH:mm\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1,[\"remoteInterview\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"message-attachment-meta--item\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"message-attachment-meta--icon location-icon\"],[12],[13],[2,\"\\n      \"],[1,[32,1,[\"address\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"message-attachment-meta--item\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"message-attachment-meta--icon camera-icon\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,0],[\"message-attachment.remote-interview-meta\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"moment-format\",\"unless\"]}",
    "meta": {
      "moduleName": "front/components/application-message/message-attachment-meta.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});