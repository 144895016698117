define("front/components/cookies/cookies-bar", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.clearConsentCookies = _exports.setCookie = void 0;

  var setCookie = function setCookie(name, value, expDays) {
    var date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires + '; path=/';
  };

  _exports.setCookie = setCookie;

  var clearConsentCookies = function clearConsentCookies() {
    var expiredDate = new Date(0).toUTCString();
    document.cookie = 'cookie_preference_analytics=;expires=' + expiredDate;
    document.cookie = 'cookie_preference_preferences=;expires=' + expiredDate;
    document.cookie = 'cookie_preference_personal=;expires=' + expiredDate;
  };

  _exports.clearConsentCookies = clearConsentCookies;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['cookies-bar'],
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    gtm: Ember.inject.service(),
    profile: Ember.computed.alias('currentSession.profile'),
    consentAll: function consentAll() {
      if (this.get('profile.id')) {
        this.set('profile.cookies.statistics', true);
        this.set('cookies.profileconsent', true);
        this.profile.save();
      }

      setCookie('cookie_preference_analytics', 'yes', 365);
      setCookie('cookie_preference_preferences', 'yes', 365);
      setCookie('cookie_preference_personal', 'yes', 365);
      setCookie('cookie_preference_neccesary', 'yes', 365);
      this.gtm.grantConsent();
      this.set('cookies.cookiesstatistics', true);
      this.set('cookies.consent', true);
    }
  });

  _exports.default = _default;
});