define("front/templates/brands/brand/jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HF7eeoCK",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"brand-jobs--container\"],[12],[2,\"\\n  \"],[8,\"jobs-react/jobs-widget-react\",[],[[\"@brand\",\"@brandColor\"],[[32,1,[\"brand\",\"id\"]],\"#ff7200\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/brands/brand/jobs.hbs"
    }
  });

  _exports.default = _default;
});