define("front/helpers/prod", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var environment = _environment.default.environment;

  var _default = Ember.Helper.helper(function prod() {
    return environment === 'production';
  });

  _exports.default = _default;
});