define("front/components/cookies/cookies-settings", ["exports", "front/components/modals/modal", "front/components/cookies/cookies-bar"], function (_exports, _modal, _cookiesBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    gtm: Ember.inject.service(),
    consent: function consent() {
      this.gtm.grantConsent();
      this.set('cookies.consent', true);
      (0, _cookiesBar.setCookie)('cookie_preference_analytics', this.get('cookies.cookiesstatistics') ? 'yes' : 'no', 365);
      (0, _cookiesBar.setCookie)('cookie_preference_preferences', this.get('cookies.cookiesoptions') ? 'yes' : 'no', 365);
      (0, _cookiesBar.setCookie)('cookie_preference_personal', this.get('cookies.cookiespersonalization') ? 'yes' : 'no', 365);
      (0, _cookiesBar.setCookie)('cookie_preference_neccesary', 'yes', 365);
    },
    consentAll: function consentAll() {
      this.set('cookies.cookiesoptions', true);
      this.set('cookies.cookiesstatistics', true);
      this.set('cookies.cookiespersonalization', true);
      (0, _cookiesBar.setCookie)('cookie_preference_analytics', 'yes', 365);
      (0, _cookiesBar.setCookie)('cookie_preference_preferences', 'yes', 365);
      (0, _cookiesBar.setCookie)('cookie_preference_personal', 'yes', 365);
      (0, _cookiesBar.setCookie)('cookie_preference_neccesary', 'yes', 365);
      this.gtm.grantConsent();
      this.set('cookies.consent', true);
    }
  });

  _exports.default = _default;
});