define("front/components/profile/row-education", ["exports", "front/components/profile/_edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({});

  _exports.default = _default;
});