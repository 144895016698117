define("front/models/event-attachment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    address: (0, _model.attr)('string'),
    date: (0, _model.attr)('date'),
    dateTo: (0, _model.attr)('string'),
    responseStatus: (0, _model.attr)('string'),
    gpsCoordinates: (0, _model.attr)(),
    remoteInterview: (0, _model.attr)()
  });

  _exports.default = _default;
});