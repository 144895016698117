define("front/templates/user/inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gEyVKQ8R",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"user--inbox\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-container --flex\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"user/inbox/applications\",[],[[\"@applications\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"user/inbox/intro\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[32,0,[\"success\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"modals/application-success\",[],[[\"@toggle\"],[[30,[36,0],[\"success\",[32,0]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle\",\"-outlet\",\"component\",\"model\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/user/inbox.hbs"
    }
  });

  _exports.default = _default;
});