define("front/routes/brands/index", ["exports", "front/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    intl: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('title', this.intl.t('menu.brands'));
    }
  });

  _exports.default = _default;
});