define("front/controllers/index", ["exports", "ember-local-storage", "front/config/environment", "jquery"], function (_exports, _emberLocalStorage, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$REGION = _environment.default.REGION,
      routes = _config$REGION.routes,
      meta = _config$REGION.meta;

  var _default = Ember.Controller.extend({
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    meta: meta,
    alljobs: routes.alljobs,
    categories: Ember.computed.filter('model.categories', function (category) {
      return category.count > 0;
    }),
    actions: {
      scrollTo: function scrollTo(element) {
        (0, _jquery.default)('body').animate({
          scrollTop: (0, _jquery.default)(element).offset().top
        }, 1000);
      },
      search: function search(query) {
        this.transitionToRoute('category', this.alljobs, {
          queryParams: {
            search: query
          }
        });
      },
      openCategory: function openCategory(category) {
        this.transitionToRoute('category', category.id);
      }
    }
  });

  _exports.default = _default;
});