define("front/templates/components/pricing-item-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WPjecWJA",
    "block": "{\"symbols\":[\"@title\",\"@price\"],\"statements\":[[10,\"div\"],[14,0,\"tw-text-left\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tw-text-[18px]\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"tw-mb-2\"],[12],[10,\"strong\"],[12],[1,[32,1]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"tw-font-bold tw-text-blue-500\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "front/templates/components/pricing-item-label.hbs"
    }
  });

  _exports.default = _default;
});