define("front/components/jobs/job-items-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    placeholderCount: 12,
    placeholders: Ember.computed('placeholderCount', function () {
      var placeholders = [];

      for (var i = 0; i < this.placeholderCount; i++) {
        placeholders.push(i);
      }

      return placeholders;
    })
  });

  _exports.default = _default;
});