define("front/components/companies/pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pricing', 'companies--section'],
    classNameBindings: ['darker'],
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('products', [{
        name: 'boost'
      }, {
        name: 'facebook'
      }, {
        name: 'esign'
      }, {
        name: 'spotlight'
      }, {
        name: 'visir_spotlight'
      }, {
        name: 'courses_spotlight'
      }, {
        name: 'visir_upsell',
        subProducts: ['[0]', '[1]'] // used to index translation of the sub products in the translation system

      }, {
        name: 'general_application',
        subPrices: ['[0]', '[1]']
      } // used to index translation of the sub prices in the translation system
      ]);
    }
  });

  _exports.default = _default;
});