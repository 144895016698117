define("front/components/companies/remote-interviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Companies::ImageSection
    @id="fjarvidtol"
    @darker={{@darker}}
    @title={{t "remote-interviews.title"}}
    @image={{region-asset "remote-interviews.png"}}>
    <p>
      {{t "remote-interviews.p1"}}
    </p>
    <p>
      {{t "remote-interviews.p2"}}
    </p>
    <p>
      {{t "remote-interviews.p3"}}
    </p>
  </Companies::ImageSection>
  
  */
  {
    "id": "2OoqSbca",
    "block": "{\"symbols\":[\"@darker\"],\"statements\":[[8,\"companies/image-section\",[],[[\"@id\",\"@darker\",\"@title\",\"@image\"],[\"fjarvidtol\",[32,1],[30,[36,0],[\"remote-interviews.title\"],null],[30,[36,1],[\"remote-interviews.png\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"remote-interviews.p1\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"remote-interviews.p2\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"remote-interviews.p3\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"region-asset\"]}",
    "meta": {
      "moduleName": "front/components/companies/remote-interviews.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});