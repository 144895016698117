define("front/components/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['check-box'],
    classNameBindings: ['checked'],
    click: function click() {
      if (!this.computed) {
        this.toggleProperty('checked');
      }

      if (this.action) {
        this.action();
      }

      return false;
    }
  });

  _exports.default = _default;
});