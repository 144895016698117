define("front/templates/components/mobile-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MRv9uCgd",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,4],[\"android.device\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"google-play-banner\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,0,\"app-close\"],[16,\"title\",[30,[36,0],[\"string.close\"],null]],[4,[38,1],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n      \"],[10,\"a\"],[15,6,[31,[\"https://play.google.com/store/apps/details?id=\",[34,2],\"&referrer=utm_source%3Dalfredweb%26utm_campaign%3DhomePage%26ig_mid%3DXAZRKAAEAAFQZEDL4SG8SD51IZ6X%26utm_content%3Dlo%26utm_medium%3DinstallLink\"]]],[14,\"role\",\"alert\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[14,0,\"app-logo\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"app-info\"],[12],[2,\"\\n          \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"mobile.app.name\"],null]],[13],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,0],[\"website.url\"],null]],[13],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,0],[\"mobile.google_banner.free_text\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"app-open\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"app-btn\"],[12],[1,[30,[36,0],[\"mobile.google_banner.install\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"appId\",\"if\",\"is-mobile\"]}",
    "meta": {
      "moduleName": "front/templates/components/mobile-banner.hbs"
    }
  });

  _exports.default = _default;
});