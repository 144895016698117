define("front/templates/components/categories-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3D/XoGX5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"header/main\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"--title text-center upper\"],[12],[1,[30,[36,1],[\"index.title\"],[[\"bold\",\"htmlSafe\"],[[30,[36,2],[[30,[36,1],[\"index.title_bold\"],null]],null],true]]]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"--search\"],[12],[2,\"\\n      \"],[8,\"input\",[],[[\"@value\",\"@enter\",\"@placeholder\",\"@aria-label\",\"@autocomplete\"],[[34,3],[34,4],[30,[36,1],[\"search-bar.placeholder\"],null],[30,[36,1],[\"search-bar.placeholder\"],null],\"off\"]],null],[2,\"\\n\"],[6,[37,5],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"remove\"],[4,[38,0],[[32,0],\"clearSearch\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"bold\",\"query\",\"search\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/components/categories-header.hbs"
    }
  });

  _exports.default = _default;
});