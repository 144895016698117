define("front/helpers/get-numbers-list", ["exports", "alfred-company-profile/helpers/get-numbers-list"], function (_exports, _getNumbersList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getNumbersList.default;
    }
  });
  Object.defineProperty(_exports, "getNumbersList", {
    enumerable: true,
    get: function get() {
      return _getNumbersList.getNumbersList;
    }
  });
});