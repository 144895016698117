define("front/components/skills/context-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="skills-context-switcher">
    <div class="skills-context-switcher--item
      {{if (eq @context "own") "selected"}}" {{on "click" (fn @changeContext "own")}}>{{t "profile.add-skills.my-skills"}}
    </div>
    <div class="skills-context-switcher--item
      {{if (eq @context "categories") "selected"}}" {{on "click" (fn @changeContext "categories")}}>{{t "profile.add-skills.categories"}}
    </div>
  </div>
  
  */
  {
    "id": "2f6ENihy",
    "block": "{\"symbols\":[\"@context\",\"@changeContext\"],\"statements\":[[10,\"div\"],[14,0,\"skills-context-switcher\"],[12],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"skills-context-switcher--item\\n    \",[30,[36,1],[[30,[36,0],[[32,1],\"own\"],null],\"selected\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,2],\"own\"],null]],null],[12],[1,[30,[36,4],[\"profile.add-skills.my-skills\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"skills-context-switcher--item\\n    \",[30,[36,1],[[30,[36,0],[[32,1],\"categories\"],null],\"selected\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,2],\"categories\"],null]],null],[12],[1,[30,[36,4],[\"profile.add-skills.categories\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "front/components/skills/context-switcher.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});