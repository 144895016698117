define("front/routes/companies/videointerviews", ["exports", "front/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.set('title', this.intl.t('videointerviews.title'));
    }
  });

  _exports.default = _default;
});