define("front/templates/components/jobs/job-items-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YilJ6p45",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"jobs/job-item-placeholder\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"placeholders\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "front/templates/components/jobs/job-items-placeholder.hbs"
    }
  });

  _exports.default = _default;
});