define("front/adapters/authorized", ["exports", "front/adapters/application", "ember-local-storage", "front/config/environment"], function (_exports, _application, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var locale = _environment.default.REGION.locale;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    headers: Ember.computed('session.data.authenticated.{access_token,token_type}', 'session.isAuthenticated', 'storage.lang', function () {
      var headers = {
        'Accept-Language': this.storage.get('lang') || locale
      };

      if (this.session.isAuthenticated) {
        headers['Authorization'] = "".concat(this.session.data.authenticated.token_type, " ").concat(this.session.data.authenticated.access_token);
      }

      return headers;
    })
  });

  _exports.default = _default;
});