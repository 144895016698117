define("front/adapters/category", ["exports", "front/adapters/_app", "front/config/environment"], function (_exports, _app, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NAMESPACE = _environment.default.NAMESPACE;

  var _default = _app.default.extend({
    namespace: NAMESPACE
  });

  _exports.default = _default;
});