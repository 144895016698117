define("front/templates/brands/brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p0H5wz8F",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,0],[[32,1,[\"brand\",\"name\"]]],[[\"_deprecate\"],[\"/tmp/broccoli-514huOA165cXfhD/out-709-colocated_template_processor/front/templates/brands/brand.hbs\"]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"company-profiles\"],[12],[2,\"\\n  \"],[8,\"header/main\",[],[[\"@stickyNav\"],[false]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[8,\"company-profile/company-profile-header\",[],[[\"@image\",\"@logo\",\"@name\",\"@color\",\"@generalApplicationRoute\",\"@generalApplicationSlug\",\"@title\"],[[31,[[32,1,[\"brand\",\"cover\"]]]],[31,[[32,1,[\"brand\",\"logo\"]]]],[31,[[32,1,[\"brand\",\"name\"]]]],\"#ff7200\",\"job\",[32,1,[\"brand\",\"generalApplicationJob\",\"slug\"]],[31,[[32,1,[\"brand\",\"slogan\"]]]]]],null],[2,\"\\n    \"],[8,\"company-profile/ui/nav-bar\",[],[[\"@profileRoute\",\"@jobsRoute\",\"@jobsCount\",\"@theme\"],[\"brands.brand.index\",\"brands.brand.jobs\",[32,1,[\"brand\",\"jobCount\"]],\"#ff7200\"]],null],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "front/templates/brands/brand.hbs"
    }
  });

  _exports.default = _default;
});