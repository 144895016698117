define("front/components/user/inbox/cancel-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ModalDialog @onClose={{@toggle}}
    @overlayClass="modal-popup cancelModal">
    <div class="default-dialog text-center">
      <a class="mob-close dark"
        {{action "close"}}></a>
  
      <h2>{{t "components.cancel_application_modal.title"}}</h2>
  
      {{!-- <p>
        {{t "components.cancel_application_modal.p"}}
      </p> --}}
  
      {{!-- <div class="form">
        {{textarea value=message maxlength=250}}
     </div> --}}
  
      <div class="buttons">
        <button class="btn btn-brd-gray upper inline"
          {{on "click" @toggle}}>{{t "string.cancel"}}</button>
        <button class="btn btn-red upper inline"
          {{on "click" @confirm}}>{{t "profile.withdraw"}}</button>
      </div>
  
    </div>
  </ModalDialog>
  
  */
  {
    "id": "3Fop3as/",
    "block": "{\"symbols\":[\"@toggle\",\"@confirm\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[32,1],\"modal-popup cancelModal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"mob-close dark\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.cancel_application_modal.title\"],null]],[13],[2,\"\\n\\n\"],[2,\"\\n\"],[2,\"\\n    \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-brd-gray upper inline\"],[4,[38,2],[\"click\",[32,1]],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-red upper inline\"],[4,[38,2],[\"click\",[32,2]],null],[12],[1,[30,[36,1],[\"profile.withdraw\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"on\"]}",
    "meta": {
      "moduleName": "front/components/user/inbox/cancel-application.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});