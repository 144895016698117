define("front/templates/_error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QEQl8HDr",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"container text-center fourohfour\"],[12],[2,\"\\n\\n   \"],[10,\"br\"],[12],[13],[2,\"\\n   \"],[10,\"br\"],[12],[13],[2,\"\\n\\n   \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"alerts.error\"],null]],[13],[2,\"\\n\\n   \"],[10,\"br\"],[12],[13],[2,\"\\n   \"],[10,\"br\"],[12],[13],[2,\"\\n\\n   \"],[10,\"a\"],[14,6,\"/\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/img/rocket.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n   \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "front/templates/_error.hbs"
    }
  });

  _exports.default = _default;
});