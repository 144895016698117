define("front/components/skills/search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="skills-search-results">
    {{#if @results.length}}
      <Skills::ManageSkillsList
        @removeProfileSkill={{@removeProfileSkill}}
        @items={{@results}}
        @forceOpen={{true}} />
    {{else}}
      <p class="skills-search-results--not-found">
        {{t "profile.skills-not-found"}}
      </p>
    {{/if}}
  </div>
  
  */
  {
    "id": "93T1QaTl",
    "block": "{\"symbols\":[\"@removeProfileSkill\",\"@results\"],\"statements\":[[10,\"div\"],[14,0,\"skills-search-results\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"skills/manage-skills-list\",[],[[\"@removeProfileSkill\",\"@items\",\"@forceOpen\"],[[32,1],[32,2],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"skills-search-results--not-found\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"profile.skills-not-found\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "front/components/skills/search-results.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});