define("front/templates/components/apply/apply-processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vvhAB3uP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"logo shakeit\"],[12],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"components.job_apply.processing.one_moment\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"components.job_apply.processing.note\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/apply/apply-processing.hbs"
    }
  });

  _exports.default = _default;
});