define("front/storages/cookies", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return {
        consent: null,
        cookiesoptions: true,
        cookiesstatistics: true
      };
    }
  });
  var _default = Storage;
  _exports.default = _default;
});