define("front/components/profile/profile-skills-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="profile-skills-empty">
    <div class="profile-skills-empty--title">{{t "profile.skills.empty.title"}}</div>
    <div class="profile-skills-empty--content">{{t "profile.skills.empty.p"}}</div>
  </div>
  
  */
  {
    "id": "cRrxcFOi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"profile-skills-empty\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"profile-skills-empty--title\"],[12],[1,[30,[36,0],[\"profile.skills.empty.title\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"profile-skills-empty--content\"],[12],[1,[30,[36,0],[\"profile.skills.empty.p\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "front/components/profile/profile-skills-empty.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});