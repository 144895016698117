define("front/adapters/profile", ["exports", "front/adapters/authorized"], function (_exports, _authorized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return "".concat(this._super.apply(this, arguments), "/me");
      }

      return this._super.apply(this, arguments);
    } // urlForUpdateRecord(id, modelName, snapshot) {
    //   return this._super('me', modelName, snapshot);
    // }

  });

  _exports.default = _default;
});