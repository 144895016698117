define("front/services/torii-session", ["exports", "torii/services/torii-session"], function (_exports, _toriiSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _toriiSession.default;
    }
  });
});