define("front/templates/job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8QESSdSL",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],[[30,[36,2],[[32,0,[\"jobModel\",\"jobType\"]],\" \",[30,[36,1],[[32,0,[\"jobModel\",\"address\",\"administrativeAreaLevel2\"]],[32,0,[\"jobModel\",\"address\",\"subLocality\"]]],null]],null]],[[\"_deprecate\"],[\"/tmp/broccoli-514huOA165cXfhD/out-709-colocated_template_processor/front/templates/job.hbs\"]]]],[2,\"\\n\"],[1,[30,[36,3],[[32,0,[\"jobModel\",\"brand\",\"name\"]]],[[\"_deprecate\"],[\"/tmp/broccoli-514huOA165cXfhD/out-709-colocated_template_processor/front/templates/job.hbs\"]]]],[2,\"\\n\"],[1,[30,[36,3],[[32,0,[\"jobModel\",\"title\"]]],[[\"_deprecate\"],[\"/tmp/broccoli-514huOA165cXfhD/out-709-colocated_template_processor/front/templates/job.hbs\"]]]],[2,\"\\n\"],[8,\"header/main\",[],[[\"@stickyNav\"],[false]],null],[2,\"\\n\"],[8,\"jobs-react/job-detail-react\",[],[[\"@slug\"],[[32,0,[\"model\",\"slug\"]]]],null],[2,\"\\n\"],[8,\"jobs/job-detail\",[],[[\"@slug\",\"@job\",\"@applyModalVisible\",\"@toggleApplyModal\"],[[32,0,[\"model\",\"slug\"]],[32,0,[\"jobModel\"]],[32,0,[\"applyModalVisible\"]],[32,0,[\"toggleApplyModal\"]]]],null],[2,\"\\n\"],[6,[37,4],[[32,0,[\"loginModalVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"login/index\",[],[[\"@afterLogin\",\"@toggle\",\"@title\"],[[32,0,[\"afterLogin\"]],[32,0,[\"toggleLoginModal\"]],[30,[36,0],[\"components.job_apply.apply_login.login\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"concat\",\"page-title\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/job.hbs"
    }
  });

  _exports.default = _default;
});