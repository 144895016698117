define("front/components/env-strip", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var environment = _environment.default.environment,
      region = _environment.default.REGION.region;

  var _default = Ember.Component.extend({
    classNameBindings: ['show:env-strip:', 'target'],
    show: environment !== 'production',
    target: environment,
    region: region
  });

  _exports.default = _default;
});