define("front/templates/components/pricing-featured-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AuE1f6Cr",
    "block": "{\"symbols\":[\"@theme\",\"@title\",\"@price\",\"@description\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[[30,[36,0],[[32,1],\"blue\"],null],\"tw-bg-blue-500 tw-text-white\",\"tw-bg-white\"],null],\"\\n    tw-rounded-[20px] tw-p-6 sm:tw-p-12 tw-flex tw-items-center tw-flex-col tw-shadow-lg tw-text-center tw-max-w-[380px] tw-w-full tw-mx-auto md:tw-w-[340px]\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"tw-text-md tw-font-500 \",[30,[36,1],[[30,[36,0],[[32,1],\"blue\"],null],\"tw-text-blue-100\"],null]]]],[12],[1,[32,2]],[13],[2,\"\\n  \"],[10,\"strong\"],[14,0,\"tw-text-3xl tw-mt-2\"],[12],[1,[32,3]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tw-text-md tw-font-500\"],[12],[1,[32,4]],[13],[2,\"\\n  \"],[18,5,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/components/pricing-featured-item.hbs"
    }
  });

  _exports.default = _default;
});