define("front/adapters/jobapplication", ["exports", "front/adapters/authorized"], function (_exports, _authorized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend({
    pathForType: function pathForType() {
      return 'applications';
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var baseUrl = this.buildURL(modelName);
      return "".concat(baseUrl, "/me");
    }
  });

  _exports.default = _default;
});