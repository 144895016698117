define("front/routes/blog", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var routes = _environment.default.REGION.routes;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('title', routes.blog[1]);
    }
  });

  _exports.default = _default;
});