define("front/components/file-upload", ["exports", "ember-uploader/components/file-field"], function (_exports, _fileField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    uploadLimit: 31457280,
    // 30MB
    gtm: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    apiManager: Ember.inject.service(),
    getBase64: function getBase64(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
          return resolve(reader.result);
        };

        reader.onerror = function (error) {
          return reject(error);
        };
      });
    },
    upload: function upload(file) {
      var _this = this;

      this.loading();
      this.getBase64(file).then(function (content) {
        _this.apiManager.post("/profiles/".concat(_this.currentSession.profile.id, "/attachments"), {
          name: file.name,
          content: content
        }).then(function (res) {
          _this.store.pushPayload('attachment', res);

          var attachmentModel = _this.store.peekRecord('attachment', res.attachment.id);

          attachmentModel.set('selected', true);

          _this.currentSession.profile.get('attachments').pushObject(attachmentModel);

          _this.gtm.trackProfileNewAttachment();
        }).catch(function (err) {
          return _this.notifications.error(err.message);
        }).finally(function () {
          return _this.loading();
        });
      });
    },
    filesDidChange: function filesDidChange(files) {
      if (!Ember.isEmpty(files)) {
        if (files[0].size <= this.uploadLimit) {
          this.upload(files[0]);
        } else {
          this.notifications.error(this.intl.t('string.toobigfile'));
        }
      }
    }
  });

  _exports.default = _default;
});