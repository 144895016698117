define("front/components/companies/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Companies::ImageSection @darker={{@darker}}
    @id="companies-app"
    @title={{t "companies.app.title"}}
    @image={{region-asset "client-app.png"}}>
    <h3>
      {{t "companies.app.subtitle"}}
    </h3>
    <p>
      {{t "companies.app.p"}}
    </p>
    <p>
      <button class="btn"
        {{on "click" @toggle}}>
        {{t "companies.app.download"}}
      </button>
    </p>
  </Companies::ImageSection>
  
  */
  {
    "id": "MNbRlaRt",
    "block": "{\"symbols\":[\"@darker\",\"@toggle\"],\"statements\":[[8,\"companies/image-section\",[],[[\"@darker\",\"@id\",\"@title\",\"@image\"],[[32,1],\"companies-app\",[30,[36,0],[\"companies.app.title\"],null],[30,[36,1],[\"client-app.png\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"companies.app.subtitle\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"companies.app.p\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,2],[\"click\",[32,2]],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"companies.app.download\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"region-asset\",\"on\"]}",
    "meta": {
      "moduleName": "front/components/companies/app.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});