define("front/components/jobs/job-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['job-filters'],
    clearAreas: function clearAreas() {
      this.areas.forEach(function (area) {
        area.set('selected');
      });
    },
    clearJobtypes: function clearJobtypes() {
      this.jobtypes.forEach(function (jobtype) {
        jobtype.set('selected');
      });
    },
    clearJobtags: function clearJobtags() {
      this.categories.forEach(function (category) {
        category.set('checkAll');
        category.jobTags.forEach(function (jobtag) {
          jobtag.set('selected');
        });
      });
    },
    clearFilter: function clearFilter() {
      this.clearAreas();
      this.clearJobtypes();
      this.clearJobtags();
      this.refresh();
    },
    actions: {
      clearTag: function clearTag() {
        this.clearTag();
      },
      showAll: function showAll() {
        this.set('adtype');
      },
      showWithProfile: function showWithProfile() {
        this.set('adtype', '2');
      },
      toggleJobType: function toggleJobType(id) {
        var jobtype = this.jobtypes.findBy('id', id);
        jobtype.set('selected', !jobtype.selected);
        this.refresh();
      },
      debouncedSearch: function debouncedSearch() {
        var _this = this;

        if (this.query && this.query.length > 2) {
          Ember.run.debounce(this, function () {
            return _this.search();
          }, 1000);
        }
      }
    }
  });

  _exports.default = _default;
});