define("front/components/apply/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['progress-bar'],
    statusColor: Ember.computed('progress', function () {
      if (this.percentage) {
        if (this.progress <= 50) {
          return 'red';
        }

        if (this.progress < 75) {
          return 'yellow';
        }

        return 'green';
      }
    }),
    progress: Ember.computed('percentage', 'currentNumber', 'total', function () {
      if (!this.percentage) {
        return Math.round(this.currentNumber / this.total * 100);
      }
    })
  });

  _exports.default = _default;
});