define("front/routes/christmasjobs", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var routes = _environment.default.REGION.routes;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        areas: this.store.findAll('area')
      }).then(function (res) {
        var applicationCntrl = _this.controllerFor('application');

        applicationCntrl.set('hideLoader');
        return res;
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('title', routes.christmasjobs[1]);
    }
  });

  _exports.default = _default;
});