define("front/controllers/profile/settings", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loginManager: Ember.inject.service(),
    profile: Ember.computed.alias('currentSession.profile'),
    cookies: (0, _emberLocalStorage.storageFor)('cookies'),
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    save: function save() {
      this.set('cookies.profileconsent', true);
      this.profile.saveCookies(this.profile.cookies);
    },
    save1: function save1() {
      this.toggleProperty('profile.cookies.options');
    },
    save2: function save2() {
      this.toggleProperty('profile.cookies.statistics');
      this.save();
    },
    save3: function save3() {
      this.toggleProperty('profile.cookies.personalization');
      this.save();
    },
    logout: function logout() {
      var _this = this;

      this.loginManager.logout(function () {
        _this.transitionToRoute('index');
      });
    },
    deleteProfile: function deleteProfile() {
      var _this2 = this;

      this.profile.deleteProfile().then(function () {
        _this2.logout();
      });
    }
  });

  _exports.default = _default;
});