define("front/components/modals/profile-edit-skills", ["exports", "front/components/modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    apiManager: Ember.inject.service(),
    skillSearch: Ember.observer('newskill', function () {
      var _this = this;

      if (this.get('newskill.length') >= 2) {
        this.apiManager.get("/profiles/me/skills/search?q=".concat(this.newskill)).then(function (data) {
          _this.set('foundSkills', data.values);
        });
      }
    }),
    actions: {
      saveProfile: function saveProfile() {
        this.save();
      },
      cancelEdit: function cancelEdit() {
        this.cancel();
      },
      addSkill: function addSkill(model) {
        if (this.newskill && this.newskill.trim()) {
          var skills = this.newskill;

          if (model.get('skills') && model.get('skills').trim()) {
            skills = model.get('skills') + ', ' + this.newskill;
          }

          model.set('skills', skills);
          this.set('newskill');
        }
      },
      removeSkill: function removeSkill(index) {
        var skill_tags = this.get('profile.skill_tags');
        skill_tags.splice(index, 1);
        var skills = skill_tags.join(', ');
        this.set('profile.skills', skills);
      },
      selectSkill: function selectSkill(skill) {
        var skills = skill;

        if (this.get('profile.skills') && this.get('profile.skills').trim()) {
          skills = this.get('profile.skills') + ', ' + skill;
        }

        this.set('profile.skills', skills);
      }
    }
  });

  _exports.default = _default;
});