define("front/components/apply/select-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-item'],
    classNameBindings: ['selected:selected'],
    tagName: 'li',
    click: function click() {
      this.toggleAction();
    }
  });

  _exports.default = _default;
});