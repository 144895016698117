define("front/components/video/video-stream", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    sound: 0.005,
    soundCheckObserver: Ember.observer('sound', function () {
      if (this.sound >= 0.1) {
        this.set('soundCheck', true);
      }
    }),
    mediaOptions: function mediaOptions(deviceId, audioId) {
      this.set('storage.videosettings', {
        video: deviceId,
        audio: audioId
      });
      return {
        video: {
          width: 720,
          height: 500,
          facingMode: 'user',
          deviceId: {
            exact: deviceId
          }
        },
        audio: {
          deviceId: {
            exact: audioId
          }
        }
      };
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.videoInputs = [];
      this.audioInputs = [];
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        devices.forEach(function (device) {
          if (device.kind === 'videoinput') {
            _this.videoInputs.addObject(device);
          } else if (device.kind === 'audioinput' && (device.label || device.deviceId === 'default')) {
            _this.audioInputs.addObject(device);
          }
        });

        if (_this.get('storage.videosettings')) {
          _this.set('videoManager.selectedVideo', _this.get('storage.videosettings.video'));

          _this.set('videoManager.selectedAudio', _this.get('storage.videosettings.audio'));
        }

        if (!_this.get('videoManager.selectedVideo')) {
          _this.set('videoManager.selectedVideo', _this.get('videoInputs.firstObject.deviceId'));
        }

        if (!_this.get('videoManager.selectedAudio')) {
          _this.set('videoManager.selectedAudio', _this.get('audioInputs.firstObject.deviceId'));
        }

        _this.getUserMedia(_this.mediaOptions(_this.get('videoManager.selectedVideo'), _this.get('videoManager.selectedAudio')));
      }).catch(function (error) {
        console.log('No devices', error);
      });
    },
    willDestroyElement: function willDestroyElement() {
      // this.stopStream();
      Ember.run.cancel(this.runCheck);

      this._super.apply(this, arguments);
    },
    getUserMedia: function getUserMedia(options) {
      var _this2 = this;

      navigator.mediaDevices.getUserMedia(options).then(function (stream) {
        _this2.set('showInfo');

        _this2.set('soundCheck');

        _this2.set('permissionCheck');

        _this2.set('permissionDenied');

        _this2.runCheck = Ember.run.later(_this2, function () {
          if (!this.soundCheck) {
            this.set('showInfo', true);
          }
        }, 3000);

        _this2.set('videoManager.visrc', URL.createObjectURL(stream));

        _this2.set('videoManager.stream', stream);
      }).catch(function (error) {
        console.log('No access', error);

        if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
          _this2.set('permissionCheck');

          _this2.set('permissionDenied', true);
        } else if (error.name === 'PermissionDismissedError') {
          _this2.set('permissionCheck', true);
        }
      });
    },
    actions: {
      selectVideo: function selectVideo(deviceId) {
        this.videoManager.stopStream();
        this.set('videoManager.selectedVideo', deviceId);
        this.getUserMedia(this.mediaOptions(deviceId, this.get('videoManager.selectedAudio')));
      },
      selectAudio: function selectAudio(deviceId) {
        this.videoManager.stopStream();
        this.set('videoManager.selectedAudio', deviceId);
        this.getUserMedia(this.mediaOptions(this.get('videoManager.selectedVideo'), deviceId));
      }
    }
  });

  _exports.default = _default;
});