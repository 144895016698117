define("front/templates/components/modals/application-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZPaDEEAH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup replyModal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"mob-close dark\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n    \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"text-center\"],[12],[1,[34,2]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"form\"],[12],[2,\"\\n      \"],[8,\"textarea\",[[16,\"placeholder\",[30,[36,4],[\"components.application_reply.write_here\"],null]]],[[\"@value\"],[[34,3]]],null],[2,\"\\n      \"],[11,\"button\"],[16,0,[31,[\"btn upper btn-\",[34,5]]]],[4,[38,0],[[32,0],[35,6],[35,3]],null],[12],[1,[30,[36,4],[\"components.application_reply.send_answer\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"title\",\"subtitle\",\"message\",\"t\",\"color\",\"sendReply\"]}",
    "meta": {
      "moduleName": "front/templates/components/modals/application-reply.hbs"
    }
  });

  _exports.default = _default;
});