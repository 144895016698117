define("front/templates/legal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Ux0e//c",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"header/main\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"pages-react/pages-react\",[],[[\"@type\",\"@id\",\"@nav\"],[\"page\",[32,1,[\"uid\"]],\"legal-navigation\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/legal.hbs"
    }
  });

  _exports.default = _default;
});