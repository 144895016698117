define("front/components/alfred-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    id: 'alfred-helper',
    tagName: 'div',
    classNames: ['alfred-helper'],
    classNameBindings: ['readyToTalk:enabled:disabled'],
    readyToTalk: false,
    delay: 1500,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.run.later(this, function () {
        this.set('readyToTalk', true);
      }, this.delay);
    }
  });

  _exports.default = _default;
});