define("front/templates/components/box-applications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "buWhkBfK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"fluid-container theme-bg\"],[14,1,\"boxApp\"],[15,5,[30,[36,0],[\"app_promo.jpg\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container wrapbox paddbox\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-10\"],[12],[2,\"\\n          \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"app.applications.h2\"],null]],[13],[2,\"\\n\\n          \"],[10,\"p\"],[12],[1,[30,[36,1],[\"app.applications.p\"],null]],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"vspace\"],[12],[13],[2,\"\\n\\n          \"],[10,\"strong\"],[12],[1,[30,[36,1],[\"app.applications.strong\"],null]],[13],[2,\"\\n          \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"app.applications.ul\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"br\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"region-bg\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/box-applications.hbs"
    }
  });

  _exports.default = _default;
});