define("front/components/ui/empty-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="empty-message">
    <div><img src="/img/icons/alfred-icon-empty.svg" alt="" /></div>
    <div class="empty-message--content">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "ly02Co5V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"empty-message\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[10,\"img\"],[14,\"src\",\"/img/icons/alfred-icon-empty.svg\"],[14,\"alt\",\"\"],[12],[13],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"empty-message--content\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/ui/empty-message.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});