define("front/templates/components/profile/row-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1I9kGIM2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,4],[[30,[36,3],[[35,2],\"modal\"],null],\"col-xs-12\"],null]],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[1,[35,0,[\"language\",\"name\"]]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,3],[[35,2],\"modal\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"gray\"],[12],[1,[35,0,[\"languageSkill\",\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[35,2],\"modal\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[30,[36,4],[[30,[36,3],[[35,2],\"modal\"],null],\"col-xs-12 text-right\",\"date\"],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"blue-darken\"],[12],[1,[35,0,[\"languageSkill\",\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,0,\"remove\"],[4,[38,6],[[32,0],\"remove\",[35,0]],[[\"bubbles\"],[false]]],[12],[10,\"img\"],[14,\"src\",\"/img/icons/trash.svg\"],[15,\"alt\",[31,[[30,[36,7],[\"string.remove\"],null]]]],[12],[13],[1,[30,[36,7],[\"string.remove\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[6,[37,5],[[32,0,[\"editModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"profile/edit/language\",[],[[\"@languageLevel\",\"@toggle\"],[[34,0],[30,[36,1],[\"editModal\",[32,0]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"languageLevel\",\"toggle\",\"context\",\"eq\",\"unless\",\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/profile/row-language.hbs"
    }
  });

  _exports.default = _default;
});