define("front/routes/profile/video/interview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    videoManager: Ember.inject.service(),
    model: function model() {
      return this.modelFor('profile.video');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.controllerFor('profile.video').set('shield', true);
      controller.set('delay', 3);
      controller.set('activeIndex', 0);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (!this.get('controller.done') && !confirm('You already started the interview. Are you sure you want to abandon progress?')) {
          transition.abort();
        } else {
          // this.controllerFor('profile.video').set('shield');
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});