define("front/components/modals/job-apply", ["exports", "front/components/modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    gtm: Ember.inject.service(),
    loginManager: Ember.inject.service(),
    store: Ember.inject.service(),
    profile: Ember.computed.alias('currentSession.profile'),
    completePercentage: Ember.computed('profile.profileStatus.properties.@each.completed', function () {
      var percentage = 0;

      if (this.get('profile.profileStatus.properties.length')) {
        this.get('profile.profileStatus.properties').filter(function (p) {
          return p.completed;
        }).map(function (p) {
          return percentage = percentage + p.percentage;
        });
      }

      return percentage;
    }),
    activeQuestion: 0,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.gtm.trackWhereApply(this.job);
      this.currentSession.load();

      if (this.job) {
        var ctas = this.currentSession.get('storage.ctas');

        if (Array.isArray(ctas)) {
          if (ctas.indexOf(this.job.id) >= 0) {
            return;
          }

          this.job.increasecta();
          ctas.push(this.job.id);
          this.set('currentSession.storage.ctas', ctas);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set('questions');
      this.set('activeQuestion');
      this.set('applyModalShown');
      this.store.unloadAll('question');
      this.store.unloadAll('questionoption');
      this.store.unloadAll('questiontype');

      this._super.apply(this, arguments);
    },
    save: function save() {
      if (this.get('questions.length')) {
        this.set('application.questions', this.questions);
      }

      this.saveApplication(this.application);
    },
    actions: {
      goBack: function goBack() {
        if (this.activeQuestion) {
          var activeQuestion = this.activeQuestion - 1;
          this.set('activeQuestion', activeQuestion);
        } else {
          this.set('questions');
        }
      },
      applyProfile: function applyProfile() {
        var _this = this;

        if (this.application) {
          return this.save();
        }

        this.set('application', this.store.createRecord('jobapplication', {
          job: this.job,
          jobId: this.job.id,
          attachments: this.profile.attachments.filter(function (a) {
            return a.selected;
          })
        }));
        this.job.questions().then(function (res) {
          if (res.questions && res.questions.length) {
            _this.store.pushPayload(res);

            _this.set('questions', _this.store.peekAll('question'));

            _this.set('activeQuestion', 0);
          } else {
            _this.save();
          }
        });
      }
    }
  });

  _exports.default = _default;
});