define("front/controllers/summerjobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search'],
    zipids: Ember.computed('model.areas.@each.selected', function () {
      if (this.get('model.areas.length')) {
        var subareaids = [];
        this.get('model.areas').filter(function (a) {
          return a.selected;
        }).forEach(function (a) {
          a.subAreas.forEach(function (sa) {
            return subareaids.push(sa.id);
          });
        });
        return subareaids;
      }
    }),
    actions: {
      search: function search() {
        this.set('search', this.query);
      },
      clear: function clear() {
        this.set('search');
        this.set('query');
      },
      openCategory: function openCategory(category) {
        this.transitionToRoute('category', category.id);
      },
      refresh: function refresh(slug) {
        if (slug) {
          this.transitionToRoute('category', slug);
        }

        this.set('tagId');
        this.send('refreshModel');
      }
    }
  });

  _exports.default = _default;
});