define("front/routes/index", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var features = _environment.default.REGION.features;

  var _default = Ember.Route.extend({
    currentSession: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.currentSession.get('profile')) {
        this.transitionTo('user.watch');
      } else {
        if (!features.landingPage) {
          this.transitionTo('jobs');
        }
      }
    }
  });

  _exports.default = _default;
});