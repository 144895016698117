define("front/components/categories-header-is", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alljobs = _environment.default.REGION.routes.alljobs;

  var _default = Ember.Component.extend({
    classNames: ['categories-header-is'],
    alljobs: alljobs
  });

  _exports.default = _default;
});