define("front/templates/companies/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Etpsq+t",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"header/main\",[],[[\"@isBlue\"],[[30,[36,0],[\"is\"],null]]],null],[2,\"\\n\\n\"],[8,\"pages-react/pages-react\",[],[[\"@type\",\"@id\"],[\"page\",[32,1,[\"uid\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"region\"]}",
    "meta": {
      "moduleName": "front/templates/companies/page.hbs"
    }
  });

  _exports.default = _default;
});