define("front/initializers/aria-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.TextField.reopen({
      attributeBindings: ['aria-label']
    });
    Ember.LinkComponent.reopen({
      attributeBindings: ['aria-label']
    });
  }

  var _default = {
    name: 'aria-labels',
    initialize: initialize
  };
  _exports.default = _default;
});