define("front/templates/components/jobs/summerjobs-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NFTngOle",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[\"is\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\"],[\"summerjobs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"summerjobs-btn--text\"],[12],[1,[30,[36,0],[\"general.open-summerjobs\"],null]],[13],[2,\" \"],[10,\"span\"],[14,0,\"icon tree\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"currentSession\",\"profile\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[\"cee\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"brigady-btn\"]],[[\"@route\"],[\"summerjobs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"general.open-summerjobs\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"region\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "front/templates/components/jobs/summerjobs-btn.hbs"
    }
  });

  _exports.default = _default;
});