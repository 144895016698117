define("front/controllers/christmasjobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('initialtagids', [388]);
    },
    actions: {
      openCategory: function openCategory(category) {
        this.transitionToRoute('category', category.id);
      }
    }
  });

  _exports.default = _default;
});