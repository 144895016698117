define("front/services/video-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global RecordRTC */

  /* global MediaStreamRecorder */

  /* global WhammyRecorder */
  var _default = Ember.Service.extend({
    apiManager: Ember.inject.service(),
    progress: Ember.computed('videos', 'done', function () {
      return this.done * 100 / this.videos;
    }),
    checkProgress: Ember.observer('progress', function () {
      if (this.progress >= 100) {
        this.clear();
        this.set('uploadedAll', true);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.videos = 0;
      this.done = 0;
      this.options = {
        type: 'video',
        mimeType: 'video/webm;codecs=h264',
        getNativeBlob: false,
        bitsPerSecond: 900000,
        video: {
          width: 720,
          height: 500
        }
      };
    },
    isMimeTypeSupported: function isMimeTypeSupported(mimeType) {
      if (typeof MediaRecorder.isTypeSupported !== 'function') {
        return true;
      }

      return MediaRecorder.isTypeSupported(mimeType);
    },
    videoIsSupported: function videoIsSupported() {
      if (navigator === undefined || navigator.mediaDevices === undefined) {
        return false;
      }

      var browser = navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/');

      if (browser[0] !== 'Chrome' || browser[0] == 'Chrome' && browser[1] < '65') {
        return false;
      }

      return true;
    },
    detectFormat: function detectFormat() {
      var mimeType = 'video/webm;codecs=h264'; // H264

      var recorderType = MediaStreamRecorder;

      if (this.isMimeTypeSupported(mimeType) === false) {
        console.log(mimeType, 'is not supported.');
        mimeType = 'video/x-matroska;codecs=avc1'; // MKV

        if (this.isMimeTypeSupported(mimeType) === false) {
          console.log(mimeType, 'is not supported.');
          mimeType = 'video/webm;codecs=vp9'; // VP9

          if (this.isMimeTypeSupported(mimeType) === false) {
            console.log(mimeType, 'is not supported.');
            mimeType = 'video/webm;codecs=vp8'; // VP8

            if (this.isMimeTypeSupported(mimeType) === false) {
              console.log(mimeType, 'is not supported.');
              mimeType = 'video/webm'; // do NOT pass any codecs (vp8 by default)

              if (this.isMimeTypeSupported(mimeType) === false) {
                console.log(mimeType, 'is not supported.'); // fallback to Whammy (WebP+WebM) solution

                mimeType = 'video/webm';
                recorderType = WhammyRecorder;
              }
            }
          }
        }
      }

      this.set('options.mimeType', mimeType);
      this.set('options.recorderType', recorderType);
    },
    startRecording: function startRecording() {
      this.detectFormat();
      this.set('recorder', RecordRTC(this.stream, this.options));
      this.recorder.startRecording();
    },
    stopRecording: function stopRecording() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.recorder.stopRecording(function () {
          var blob = _this.recorder.getBlob();

          blob = new File([blob], 'video.webm', {
            type: 'video/webm;codecs=h264'
          });
          resolve(blob);
        });
      });
    },
    questionStarted: function questionStarted(id, isDemo) {
      if (!isDemo) {
        this.apiManager.questionStarted(id, this.applicationid);
      }
    },
    saveQuestion: function saveQuestion(id, video, isDemo) {
      var _this2 = this;

      this.incrementProperty('videos');

      if (isDemo) {
        this.incrementProperty('done');
      } else {
        this.apiManager.uploadVideo(id, this.applicationid, video).finally(function () {
          _this2.incrementProperty('done');
        });
      } // (e) => {
      //     if(e.lengthComputable) {
      //         let max = e.total;
      //         let current = e.loaded;
      //         let perc = (current * 100)/max;
      //
      //         this.progresses["v"+e.total] = perc;
      //
      //         this.calculate();
      //     }
      // }

    },
    // calculate() {
    //     let p = 0;
    //     console.log(this.progresses);
    //     for (var i = 0; i < this.progresses.length; i++) {
    //         console.log(this.progresses[i]);
    //         p += this.progresses[i];
    //     }
    //
    //     this.set('progress', (p*100)/this.get('total'));
    // },
    stopStream: function stopStream() {
      this.set('visrc');

      if (this.stream) {
        this.stream.getTracks().forEach(function (track) {
          track.stop();
        }); // this.get('stream').stop();

        this.set('stream');
      }
    },
    clear: function clear() {
      this.recorder.clearRecordedData();
      this.recorder.destroy();
      this.set('recorder');
      this.set('applicationid');
    }
  });

  _exports.default = _default;
});