define("front/templates/components/modals/company-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvbfO2Ce",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup video-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n\\n   \"],[11,\"a\"],[24,0,\"close dark\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n   \"],[10,\"iframe\"],[14,\"title\",\"Video\"],[14,\"src\",\"https://www.youtube.com/embed/0tWC92J-Zio?rel=0&showinfo=0&autoplay=1\"],[14,\"frameborder\",\"0\"],[14,\"allowfullscreen\",\"\"],[12],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "front/templates/components/modals/company-video.hbs"
    }
  });

  _exports.default = _default;
});