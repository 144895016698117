define("front/components/user/inbox/intro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <User::Empty>
    <img class="icon"
      src="/img/icons/alfred-icon-empty.svg"
      alt="">
    <div class="title">{{t "profile.no_applications"}}</div>
    <div class="description">{{t "profile.no_applications_p" htmlSafe=true}}</div>
    <LinkTo @route="user.watch"
      class="btn inline">{{t "profile.jobs"}}</LinkTo>
  </User::Empty>
  
  */
  {
    "id": "arRPTbKr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user/empty\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[14,0,\"icon\"],[14,\"src\",\"/img/icons/alfred-icon-empty.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[30,[36,0],[\"profile.no_applications\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"description\"],[12],[1,[30,[36,0],[\"profile.no_applications_p\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn inline\"]],[[\"@route\"],[\"user.watch\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"profile.jobs\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "front/components/user/inbox/intro.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});