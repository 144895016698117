define("front/templates/brands/brand/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L1iZAObQ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"reset-scroll\",[],[[],[]],null],[2,\"\\n\"],[8,\"company-profile/profile-grid\",[],[[\"@color\",\"@jobsRoute\",\"@components\",\"@target\"],[\"#ff7200\",\"brands.brand.jobs\",[32,1,[\"brand\",\"components\"]],\"_self\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/brands/brand/index.hbs"
    }
  });

  _exports.default = _default;
});