define("front/controllers/profile/video/interview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    videoManager: Ember.inject.service(),
    delay: 3,
    activeIndex: 0,
    questions: Ember.computed.alias('model.viquestions'),
    isDemo: Ember.computed.equal('model.id', '0'),
    activeQuestion: Ember.computed('activeIndex', function () {
      if (this.questions) {
        return this.questions.objectAt(this.activeIndex);
      }
    }),
    lastQuestion: Ember.computed('activeIndex', 'questions.length', function () {
      if (this.activeIndex === this.get('questions.length') - 1) {
        return true;
      }

      return false;
    }),
    delayCheck: Ember.observer('delay', function () {
      if (this.delay === 1) {
        this.startTimer(this.get('activeQuestion.timelimit'));
      }
    }),
    startTimer: function startTimer(duration) {
      var _this = this;

      this.set('readyForNext');
      this.set('isRecording', true);
      this.videoManager.questionStarted(this.get('activeQuestion.id'), this.isDemo);
      Ember.run.later(this, function () {
        this.set('readyForNext', true);
      }, this.isDemo ? 2000 : 4000);
      var timer = duration,
          minutes,
          seconds;
      clearInterval(this.interval);
      this.interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? "0".concat(minutes) : minutes;
        seconds = seconds < 10 ? "0".concat(seconds) : seconds;

        _this.set('countdown', "".concat(minutes, ":").concat(seconds));

        if (--timer < 0) {
          timer = duration;

          if (_this.lastQuestion) {
            _this.finish();
          } else {
            _this.nextQuestion();
          }
        }
      }, 1000);
    },
    nextQuestion: function nextQuestion() {
      this.set('isRecording');
      this.set('countdown');
      clearInterval(this.interval);
      Ember.run.later(this, function () {
        var _this2 = this;

        this.videoManager.stopRecording().then(function (video) {
          _this2.videoManager.saveQuestion(_this2.get('activeQuestion.id'), video, _this2.isDemo);

          var activeIndex = _this2.activeIndex + 1;
          var length = _this2.get('questions.length') - 1;

          if (activeIndex <= length) {
            _this2.set('activeIndex', activeIndex);

            _this2.startTimer(_this2.get('activeQuestion.timelimit'));

            _this2.videoManager.startRecording();
          }
        });
      }, 1000);
    },
    finish: function finish() {
      var _this3 = this;

      clearInterval(this.interval);
      this.videoManager.stopRecording().then(function (video) {
        // this.get('videoManager').stopStream();
        _this3.videoManager.saveQuestion(_this3.get('activeQuestion.id'), video, _this3.isDemo);

        _this3.set('done', true);

        _this3.set('isRecording');

        _this3.transitionToRoute('profile.video.done');
      });
    },
    actions: {}
  });

  _exports.default = _default;
});