define("front/routes/user/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    manager: Ember.inject.service('profile'),
    model: function model() {
      return Ember.RSVP.hash({
        profile: this.manager.profile,
        skillCategories: this.manager.getSkillCategories(),
        profileSkills: this.manager.getProfileSkills()
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      if (model.skillCategories && model.skillCategories.categories.length) {
        model.skillCategories.categories.forEach(function (category) {
          category.subCategories.forEach(function (subcat) {
            var skills = subcat.skills.map(function (skill) {
              var maybeProfileSkill = model.profileSkills.skills.find(function (s) {
                return s.id === skill.id;
              });

              if (maybeProfileSkill) {
                skill.profileSkill = maybeProfileSkill.profileSkill;
              }

              return skill;
            });

            _this.store.pushPayload({
              skills: skills
            });

            subcat.skills = subcat.skills.map(function (skill) {
              return _this.store.peekRecord('skill', skill.id);
            });
          });
        });
        model.profile.skills = model.profileSkills.skills.map(function (skill) {
          if (!skill.approved) {
            _this.store.pushPayload('skill', {
              skill: skill
            });
          }

          return _this.store.peekRecord('skill', skill.id);
        });
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});