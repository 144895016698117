define("front/components/apply/application-summary", ["exports", "front/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var features = _environment.default.REGION.features;

  var _default = Ember.Component.extend({
    classNames: ['application-summary'],
    selectedAttachments: Ember.computed('profile.attachments.@each.selected', function () {
      return this.get('profile.attachments').filter(function (a) {
        return a.selected;
      });
    }),
    isValid: Ember.computed('requiresEsign', 'job.applyEmail', 'profile.{verification,phoneVerified,emailVerified}', function () {
      if (this.get('requiresEsign') && !this.get('profile.verification')) {
        return false;
      }

      if (features.phoneVerified && !this.get('profile.phoneVerified')) {
        return false;
      }

      if ((features.emailVerified || this.get('job.applyEmail')) && !this.get('profile.emailVerified')) {
        return false;
      }

      return true;
    }),
    context: 'MAIN',
    // one of: MAIN, ATTACHMENTS or SKILLS
    actions: {
      close: function close() {
        this.close();
      },
      showQuestions: function showQuestions() {
        this.set('showQuestions', true);
      },
      toggleLoading: function toggleLoading() {
        this.toggleProperty('isUploadingAttachment');
      },
      applyProfile: function applyProfile() {
        this.applyProfile();
      },
      toggleAttachments: function toggleAttachments() {
        if (this.context !== 'ATTACHMENTS') {
          this.set('context', 'ATTACHMENTS');

          if (!this.get('profile.attachments.length')) {
            setTimeout(function () {
              return (0, _jquery.default)('#uploader').click();
            }, 200);
          }
        } else {
          this.set('context', 'MAIN');
        }
      },
      toggleEsign: function toggleEsign() {
        this.toggleProperty('showEsign');
      },
      toggleSkills: function toggleSkills() {
        if (this.context !== 'SKILLS') {
          this.set('context', 'SKILLS');
        } else {
          this.set('context', 'MAIN');
        }
      }
    }
  });

  _exports.default = _default;
});