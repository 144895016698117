define("front/components/user/settings/check-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
      <div class="label">
      <label>{{@label}}</label>
      <small class="description">{{@description}}</small>
    </div>
  
    <div class="checkbox"></div>
  
  */
  {
    "id": "+BYoBkWK",
    "block": "{\"symbols\":[\"@label\",\"@description\"],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"label\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[32,1]],[13],[2,\"\\n    \"],[10,\"small\"],[14,0,\"description\"],[12],[1,[32,2]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/user/settings/check-item.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['check-box'],
    classNameBindings: ['checked', 'active:checked:'],
    click: function click() {
      this.toggleProperty('checked');

      if (this.action) {
        this.action();
      }

      return false;
    }
  }));

  _exports.default = _default;
});