define("front/components/textarea-auto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'textarea',
    classNames: ['textarea-auto'],
    attributeBindings: ['placeholder'],
    placeholder: null,
    height: 28,
    maxHeight: 170,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setHeight();
      this.$().bind('keydown paste', this.autosize.bind(this));
      this.$().keydown(this._enter.bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.$()) {
        this.$().val(this.value === undefined ? null : this.value);
      }
    },
    setHeight: function setHeight() {
      this.$().css('height', this.height);
    },
    autosize: function autosize() {
      Ember.run.next(this, function () {
        this.set('value', this.$().val());
        this.setHeight();
        var scrollHeight = this.$().prop('scrollHeight');

        if (scrollHeight > this.height && scrollHeight < this.maxHeight) {
          this.$().css('height', scrollHeight);
        } else if (scrollHeight > this.maxHeight) {
          this.$().css('height', this.maxHeight);
        }
      });
    },
    _enter: function _enter(event) {
      if (this.$().val() && event.which === 13 && !event.shiftKey && !event.altKey) {
        this.onEnter();
        event.preventDefault();
      }
    }
  });

  _exports.default = _default;
});