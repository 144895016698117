define("front/templates/profile/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G/eUm5Kq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"video-screen\"],[12],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"close dark\"]],[[\"@route\"],[\"user.inbox\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"demo-marker\"],[12],[2,\"Prufuviðtal\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container text-center\"],[12],[2,\"\\n\"],[6,[37,3],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[19,\"profile/video/disabled\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[19,\"profile/video/support\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"-outlet\",\"component\",\"videoIsSupported\",\"if\",\"shield\",\"unless\",\"isDemo\",\"videoDisabled\"]}",
    "meta": {
      "moduleName": "front/templates/profile/video.hbs"
    }
  });

  _exports.default = _default;
});