define("front/components/logo-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['logo-placeholder'],
    initials: Ember.computed('brand', function () {
      if (this.brand) {
        return this._parseBrand(this.brand).split(' ').map(function (b) {
          return b[0];
        }).slice(0, 3).join(' ').replace(/[\+\-\$]/g, '');
      }

      return "NA";
    }),
    initialsStyling: Ember.computed('brand', function () {
      if (this.brand) {
        var length = this.brand.split(' ').length;
        var size = length && length > 2 ? 25 : 35;
        return Ember.String.htmlSafe('font-size: ' + size + 'px;');
      }

      return Ember.String.htmlSafe('font-size: 35px;');
    }),
    _parseBrand: function _parseBrand(brand) {
      if (!brand) {
        return;
      }

      var postfixes = ['s.r.o.', 's.r.o', 'a.s.', 'z.s.'];
      postfixes.forEach(function (p) {
        return brand = brand.replace(p, '');
      });
      return brand;
    }
  });

  _exports.default = _default;
});