define("front/components/jobs/job-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['job-item'],
    classNameBindings: ['unread']
  });

  _exports.default = _default;
});