define("front/components/apply/apply-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      close: function close() {
        this.close();
      },
      applyProfile: function applyProfile() {
        this.applyProfile();
      }
    }
  });

  _exports.default = _default;
});