define("front/services/api-front", ["exports", "fetch", "ember-local-storage", "front/config/environment"], function (_exports, _fetch2, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resturl = _environment.default.REGION.app.resturl;

  var _default = Ember.Service.extend({
    resturl: resturl,
    namespace: '/api/v1',
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    loginManager: Ember.inject.service(),
    storage: (0, _emberLocalStorage.storageFor)('current-user'),
    headers: Ember.computed('intl.locale', 'session.data.authenticated.{access_token,token_type}', 'session.isAuthenticated', function () {
      var headers = {
        'Accept-Language': this.intl.locale,
        'Content-Type': 'application/json'
      };

      if (this.session.isAuthenticated) {
        headers['Authorization'] = "".concat(this.session.data.authenticated.token_type, " ").concat(this.session.data.authenticated.access_token);
      }

      return headers;
    }),
    _checkStatus: function _checkStatus(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }

      return response.json().then(function (json) {
        json.response = {
          status: response.status
        };
        throw json;
      });
    },
    _parseJSON: function _parseJSON(response) {
      return response.json().catch(function () {
        return Ember.RSVP.resolve();
      });
    },
    _fetch: function _fetch(_ref) {
      var url = _ref.url,
          body = _ref.body,
          _ref$method = _ref.method,
          method = _ref$method === void 0 ? 'GET' : _ref$method;

      if (body) {
        body = JSON.stringify(body);
      }

      return (0, _fetch2.default)("".concat(this.resturl).concat(this.namespace).concat(url), {
        method: method,
        headers: this.headers,
        body: body
      });
    },
    _request: function _request(_ref2) {
      var _this = this;

      var url = _ref2.url,
          body = _ref2.body,
          method = _ref2.method;
      return this._fetch({
        url: url,
        body: body,
        method: method
      }).then(this._checkStatus).then(this._parseJSON).catch(function (error) {
        console.log(error);

        if (error.response.status === 401) {
          return _this.loginManager.logout();
        }

        throw error;
      });
    },
    get: function get(url) {
      return this._request({
        url: url
      });
    },
    post: function post(url, body) {
      return this._request({
        url: url,
        body: body,
        method: 'POST'
      });
    },
    patch: function patch(url, body) {
      return this._request({
        url: url,
        body: body,
        method: 'PATCH'
      });
    },
    put: function put(url, body) {
      return this._request({
        url: url,
        body: body,
        method: 'PUT'
      });
    },
    delete: function _delete(url) {
      return this._request({
        url: url,
        method: 'DELETE'
      });
    },
    download: function download(url) {
      return this._fetch({
        url: url
      }).then(this._checkStatus);
    },
    questionStarted: function questionStarted(questionid, applicationid) {
      return this.patch("/applicationvideointerviews/".concat(questionid, "/started?applicationid=").concat(applicationid));
    },
    uploadVideo: function uploadVideo(questionid, applicationid, video) {
      var formData = new FormData();
      formData.append('file', video);
      return this.post("".concat(this.resturl, "/applicationvideointerviews/").concat(questionid, "/upload?applicationid=").concat(applicationid), formData);
    }
  });

  _exports.default = _default;
});