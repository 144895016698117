define("front/templates/components/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TCf7jpdU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"search\"],[12],[2,\"\\n   \"],[8,\"input\",[[16,\"placeholder\",[30,[36,1],[\"search-bar.placeholder\"],null]]],[[\"@value\",\"@enter\",\"@aria-label\"],[[34,2],[30,[36,0],[[32,0],\"search\"],null],[30,[36,1],[\"search-bar.placeholder\"],null]]],null],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[35,2],[30,[36,4],[[35,2],[35,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"   \"],[11,\"button\"],[24,0,\"btn clear\"],[4,[38,0],[[32,0],\"clear\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"   \"],[11,\"button\"],[24,0,\"btn\"],[24,\"aria-label\",\"Search\"],[4,[38,0],[[32,0],\"search\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"query\",\"search\",\"eq\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/components/search-bar.hbs"
    }
  });

  _exports.default = _default;
});