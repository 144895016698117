define("front/controllers/companies/index", ["exports", "front/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var routes = _environment.default.REGION.routes;

  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    apiManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    gtm: Ember.inject.service(),
    queryParams: ['a', 'kafli', 's'],
    checkA: Ember.observer('a', function () {
      if (this.a === routes.register) {
        this.set('companySignupModal', true);
      }
    }),
    // Section number to scroll to (1-indexed)
    scrollToSection: Ember.observer('kafli', 's', function () {
      var _this = this;

      if (this.kafli) {
        Ember.run.schedule('afterRender', this, function () {
          // Need to wait until the browser renders images, otherwise the offset value will be wrong.
          var element = document.getElementById(_this.kafli);

          if (element) {
            setTimeout(function () {
              return element.scrollIntoView({
                behavior: 'smooth'
              });
            }, 500);
          }
        });
      } else if (this.s) {
        Ember.run.schedule('afterRender', this, function () {
          // Need to wait until the browser renders images, otherwise the offset value will be wrong.
          setTimeout(function () {
            var headers = (0, _jquery.default)('h2');
            var section = Number(_this.s);

            if (headers.length >= section) {
              (0, _jquery.default)('html, body').animate({
                scrollTop: (0, _jquery.default)(headers[section]).offset().top - 150
              }, 1000);
            }
          }, 300);
        });
      }
    }),
    trackSignupBtn: function trackSignupBtn(toggle) {
      this.gtm.trackCompanySignupBtn();
      toggle();
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.logos = [];

      for (var i = 1; i <= 23; i++) {
        var num = i < 10 ? '0' + i : i;
        this.logos.push("/img/logos/".concat(num, ".png"));
      }
    },
    actions: {
      openMeetingForm: function openMeetingForm() {
        window.open('https://forms.gle/Vwratt1VBc6ancYr8');
      },
      scrollTo: function scrollTo(element) {
        (0, _jquery.default)('body,html').animate({
          scrollTop: (0, _jquery.default)(element).offset().top
        }, 1000);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "trackSignupBtn", [_dec], Object.getOwnPropertyDescriptor(_obj, "trackSignupBtn"), _obj)), _obj)));

  _exports.default = _default;
});