define("front/components/video/sound-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global webkitAudioContext */
  var _default = Ember.Component.extend({
    audioScript: null,
    data: null,
    checkMax: Ember.observer('max', function () {
      Ember.run.cancel(this.runLater);
      this.set('runLater', Ember.run.later(this, function () {
        this.set('max', this.wave);
      }, 1100));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.stream) {
        this.analyzeAudio(this.stream);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.audioContext.close();
      this.meter.shutdown();
      this.set('meter');
      Ember.run.cancel(this.runLater);
      this.set('runLater');

      this._super.apply(this, arguments);
    },
    analyzeAudio: function analyzeAudio(stream) {
      var audioContext = window.AudioContext ? new AudioContext() : new webkitAudioContext();
      var audioSource = audioContext.createMediaStreamSource(stream);
      this.set('audioContext', audioContext);
      var meter = this.createAudioMeter(audioContext);
      audioSource.connect(meter);
      this.set('meter', meter);
    },
    setMax: function setMax() {
      if (this.wave > this.max) {
        this.set('max', this.wave);
      }
    },
    createAudioMeter: function createAudioMeter(audioContext) {
      var _this = this;

      var processor = audioContext.createScriptProcessor(512);
      processor.clipping = false;
      processor.lastClip = 0;
      processor.volume = 0;
      processor.clipLevel = 0.98;
      processor.averaging = 0.95;
      processor.clipLag = 750;

      processor.onaudioprocess = function (event) {
        var buf = event.inputBuffer.getChannelData(0);
        var bufLength = buf.length;
        var sum = 0;
        var x;

        for (var i = 0; i < bufLength; i++) {
          x = buf[i];

          if (Math.abs(x) >= processor.clipLevel) {
            processor.clipping = true;
            processor.lastClip = window.performance.now();
          }

          sum += x * x;
        }

        var rms = Math.sqrt(sum / bufLength);
        processor.volume = Math.max(rms, processor.volume * processor.averaging);

        _this.set('wave', processor.volume);

        _this.setMax();
      };

      processor.connect(audioContext.destination);

      processor.checkClipping = function () {
        if (!this.clipping) {
          return false;
        }

        if (this.lastClip + this.clipLag < window.performance.now()) {
          this.clipping = false;
        }

        return this.clipping;
      };

      processor.shutdown = function () {
        this.disconnect();
        this.onaudioprocess = null;
      };

      return processor;
    }
  });

  _exports.default = _default;
});