define("front/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ytw4DdTg",
    "block": "{\"symbols\":[],\"statements\":[[8,\"header/main\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"login/index\",[],[[\"@asPage\",\"@toggle\"],[true,[32,0,[\"toggle\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "front/templates/login.hbs"
    }
  });

  _exports.default = _default;
});