define("front/routes/category", ["exports", "front/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.session.isAuthenticated) {
        return this.transitionTo('user.watch');
      }

      this.transitionTo('index');
    }
  });

  _exports.default = _default;
});