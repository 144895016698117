define("front/templates/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PHEd3XrM",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"header/main\",[],[[],[]],null],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"token\"]],[32,1,[\"documentId\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"pages-react/pages-react\",[],[[\"@type\",\"@id\"],[\"preview\",[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"pages-react/pages-react\",[],[[\"@type\",\"@id\"],[\"page\",[32,1,[\"uid\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/page.hbs"
    }
  });

  _exports.default = _default;
});