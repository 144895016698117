define("front/components/modals/company-signup", ["exports", "libphonenumber-js", "front/config/environment", "front/components/modals/modal"], function (_exports, _libphonenumberJs, _environment, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var prefix = _environment.default.REGION.prefix;

  var _default = _modal.default.extend({
    apiManager: Ember.inject.service(),
    gtm: Ember.inject.service(),
    intl: Ember.inject.service(),
    validssn: Ember.computed('ssn', function () {
      return this.ssn && this.ssn.ssn();
    }),
    validname: Ember.computed('name', function () {
      return this.name && this.name.name();
    }),
    validemail: Ember.computed('email', function () {
      return this.email && this.email.email();
    }),
    validpassword: Ember.computed.gte('password.length', 6),
    validphone: Ember.computed('phone', function () {
      if (!this.phone) {
        return;
      }

      var phoneToValidate = this.phone;

      if (phoneToValidate.startsWith('00')) {
        phoneToValidate = '+' + phoneToValidate.substr(2);
      }

      if (!phoneToValidate.startsWith('+')) {
        phoneToValidate = prefix + phoneToValidate;
      }

      return (0, _libphonenumberJs.isValidNumber)(phoneToValidate);
    }),
    isValid: Ember.computed.and('validssn', 'validname', 'validemail', 'validpassword', 'termsAccepted'),
    willDestroyElement: function willDestroyElement() {
      this.set('signupSuccess');

      this._super.apply(this, arguments);
    },
    actions: {
      check: function check(key) {
        if (!this.get("valid".concat(key)) || this.get("error".concat(key))) {
          if (this.get("".concat(key))) {
            this.set("invalid".concat(key), true);
          } else {
            this.set("invalid".concat(key), false);
          }
        } else {
          this.set("invalid".concat(key), false);
        }
      },
      signup: function signup() {
        var _this = this;

        this.set('signupLoading', true);
        this.apiManager.post('/clients', {
          ssn: this.ssn.trim(),
          name: this.name.trim(),
          email: this.email,
          password: this.password,
          phone: this.phone.trim()
        }).then(function () {
          _this.gtm.trackClientSignup();

          _this.set('signupSuccess', _this.email);
        }).catch(function (error) {
          _this.notifications.error(error.code === 500 || !error.message ? "".concat(_this.intl.t('alerts.error')) : "".concat(error.message));
        }).finally(function () {
          _this.set('signupLoading');
        });
      }
    }
  });

  _exports.default = _default;
});