define("front/components/newsletter-signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="newsletter-signup">
    <div class="newsletter-signup--left">
      <div class="newsletter-signup--title">
        Subscribe to our community newsletter
      </div>
      <div class="newsletter-signup--description">
        Don't miss out on opportunities that can help you with your job search
      </div>
    </div>
    <div class="newsletter-signup--right">
  
    </div>
  </div>
  
  */
  {
    "id": "bqjZypI1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"newsletter-signup\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"newsletter-signup--left\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"newsletter-signup--title\"],[12],[2,\"\\n      Subscribe to our community newsletter\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"newsletter-signup--description\"],[12],[2,\"\\n      Don't miss out on opportunities that can help you with your job search\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"newsletter-signup--right\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/newsletter-signup.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});