define("front/components/date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    intl: Ember.inject.service(),
    classNames: ['date-picker'],
    hasYear: true,
    hasMonth: true,
    hasDay: true,
    format: 'YYYY-MM-DD',
    months: Ember.computed('intl.locale', function () {
      return _moment.default.months();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var days = [],
          years = [],
          currentYear = (0, _moment.default)().year();

      if (this.hasYear) {
        for (var y = currentYear; y >= currentYear - 100; y--) {
          years.push("".concat(y));
        }

        this.years = years;
      }

      if (this.hasDay) {
        for (var d = 1; d <= 31; d++) {
          days.push("".concat(d));
        }

        this.days = days;
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.date) {
        if (this.hasYear) {
          this.set('year', (0, _moment.default)(this.date).format('YYYY'));
        }

        if (this.hasMonth) {
          this.set('month', (0, _moment.default)(this.date).format('MMMM'));
        }

        if (this.hasDay) {
          this.set('day', (0, _moment.default)(this.date).format('DD'));
        }
      }
    },
    setDate: function setDate() {
      if (this.hasYear && this.year && this.hasMonth && this.month && this.hasDay && this.day) {
        return this.set('date', (0, _moment.default)("".concat(this.year, "-").concat(this.month, "-").concat(this.day), 'YYYY-MMMM-DD').format(this.format));
      }

      if (this.hasYear && this.year && this.hasMonth && this.month && !this.hasDay) {
        return this.set('date', (0, _moment.default)("".concat(this.year, "-").concat(this.month), 'YYYY-MMMM').format(this.format));
      }

      if (this.hasMonth && this.month && this.hasDay && this.day && !this.hasYear) {
        return this.set('date', (0, _moment.default)("".concat(this.month, "-").concat(this.day), 'MMMM-DD').format('MM-DD'));
      }
    },
    setYear: function setYear(year) {
      this.set('year', year);
      this.setDate();
    },
    setMonth: function setMonth(month) {
      this.set('month', month);
      this.setDate();
    },
    setDay: function setDay(day) {
      this.set('day', day);
      this.setDate();
    }
  }, (_applyDecoratedDescriptor(_obj, "setYear", [_dec], Object.getOwnPropertyDescriptor(_obj, "setYear"), _obj), _applyDecoratedDescriptor(_obj, "setMonth", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setMonth"), _obj), _applyDecoratedDescriptor(_obj, "setDay", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setDay"), _obj)), _obj)));

  _exports.default = _default;
});