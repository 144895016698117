define("front/components/main-footer", ["exports", "ember-local-storage", "front/config/environment"], function (_exports, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var links = _environment.default.REGION.links;

  var _default = Ember.Component.extend({
    classNames: ['main-footer'],
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    facebook: links.facebook,
    twitter: links.twitter,
    email: links.email,
    instagram: links.instagram,
    linkedin: links.linkedin
  });

  _exports.default = _default;
});