define("front/components/screen-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['screen-loader'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().delay(0).fadeIn();
    }
  });

  _exports.default = _default;
});