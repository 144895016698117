define("front/controllers/profile/index", ["exports", "moment", "ember-local-storage"], function (_exports, _moment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    hideModals: function hideModals() {
      this.set('editAboutmeModal');
      this.set('editSkillsModal');
    },
    toggleModal: function toggleModal(modal) {
      this.toggleProperty(modal);
    },
    actions: {
      setBirthday: function setBirthday(date) {
        this.set('model.birthday', (0, _moment.default)(date).format('YYYY-MM-DD h:mm:ss'));
      },
      saveProfile: function saveProfile() {
        var _this = this;

        this.model.profile.save().then(function () {
          _this.send('refreshData');

          _this.hideModals();
        });
      },
      cancelEdit: function cancelEdit() {
        this.hideModals();
        this.model.profile.rollbackAttributes();
      },
      newLink: function newLink() {
        return this.store.createRecord('link');
      },
      saveLink: function saveLink(link) {
        var _this2 = this;

        this.model.profile.newlink({
          link: link.serialize()
        }).then(function () {
          _this2.send('refreshData');

          _this2.set('newLinkModal');
        });
      },
      saveLanguage: function saveLanguage(language) {
        var _this3 = this;

        this.model.profile.newlanguagelevel({
          languagelevel: language.serialize()
        }).then(function () {
          _this3.send('refreshData');

          _this3.set('newLanguageModal');
        });
      },
      saveExperience: function saveExperience(experience) {
        var _this4 = this;

        this.model.profile.newexperience({
          experience: experience.serialize()
        }).then(function () {
          _this4.send('refreshData');

          _this4.set('newExperienceModal');
        });
      },
      saveEducation: function saveEducation(education) {
        var _this5 = this;

        this.model.profile.neweducation({
          education: education.serialize()
        }).then(function () {
          _this5.send('refreshData');

          _this5.set('newEducationModal');
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleModal", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleModal"), _obj)), _obj)));

  _exports.default = _default;
});