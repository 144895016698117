define("front/controllers/profile/jobapplications", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var routes = _environment.default.REGION.routes;

  var _default = Ember.Controller.extend({
    queryParams: ['success', 'refresh'],
    alljobs: routes.alljobs,
    success: false,
    jobapplication: Ember.computed.alias('model.jobapplication'),
    eventDates: Ember.computed.alias('model.jobapplication.eventDates'),
    actions: {
      sendMessage: function sendMessage(newmessage) {
        var _this = this;

        this.jobapplication.sendMessage({
          'message': newmessage
        }).then(function () {
          _this.set('newmessage');

          _this.send('refreshData');
        });
      },
      withdraw: function withdraw() {
        var _this2 = this;

        this.jobapplication.withdraw({
          message: this.cancelmessage
        }).then(function () {
          _this2.send('refreshData');

          _this2.set('cancelmessage');

          _this2.set('cancelModal');
        });
      },
      archive: function archive() {
        var _this3 = this;

        this.jobapplication.destroyRecord().then(function () {
          _this3.set('archiveModal');

          _this3.set('id');

          _this3.send('refreshData');
        });
      },
      refresh: function refresh() {
        this.send('refreshData');
      }
    }
  });

  _exports.default = _default;
});