define("front/templates/watch-unsubscribe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rl8qGfEr",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"watch-unsubscribe\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,[36,1],[\"logo/small.svg\"],null]],[14,\"alt\",\"logo\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"watch-unsubscribe--content\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"error\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"watch.unsubscribe.error\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"watch.unsubscribe.text\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"watch-unsubscribe--link\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[[30,[36,3],[\"web\"],null]]]],[12],[1,[30,[36,0],[\"watch.continue\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"region-asset\",\"if\",\"get-link\"]}",
    "meta": {
      "moduleName": "front/templates/watch-unsubscribe.hbs"
    }
  });

  _exports.default = _default;
});