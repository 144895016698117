define("front/components/profile/hire-package-modal", ["exports", "front/components/modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    intl: Ember.inject.service(),
    apiManager: Ember.inject.service(),
    classNames: ['hire-package'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('loading', true);
      this.apiManager.get("/applications/".concat(this.application.id, "/events/").concat(this.message.id, "/hirepackage")).then(function (res) {
        _this.set('sections', Ember.A(res.sections));
      }).finally(function () {
        return _this.set('loading');
      });
    },
    validateQuestions: function validateQuestions() {
      return this.sections.every(function (s) {
        return s.hireQuestions.every(function (q) {
          return q.valid;
        });
      });
    },
    actions: {
      sendInfo: function sendInfo() {
        var _this2 = this;

        if (this.validateQuestions()) {
          var data = [];
          this.sections.forEach(function (s) {
            s.hireQuestions.forEach(function (q) {
              if (q.type === 'FREE_TEXT' || q.type === 'IS_SSN') {
                data.push({
                  questionId: q.id,
                  answer: q.answer
                });
              }

              if ((q.type === 'SEARCHABLE_SINGLE_OPTION' || q.type === 'SINGLE_OPTION') && q.option) {
                data.push({
                  questionId: q.id,
                  optionId: q.option.id
                });
              }

              if (q.type === 'IS_BANK') {
                data.push({
                  questionId: q.id,
                  answer: "".concat(q.bank, "-").concat(q.hb, "-").concat(q.account)
                });
              }
            });
          });
          this.set('sendingInfo', true);
          this.apiManager.post("/applications/".concat(this.application.id, "/events/").concat(this.message.id, "/hirepackage"), data).then(function () {
            _this2.notifications.success(_this2.intl.t('components.hire-package.success'));

            _this2.set('message.hirePackage.hirePackageStatus', 'HIREPACKAGEDONE');

            _this2.set('showHirePackage');
          }).finally(function () {
            return _this2.set('sendingInfo');
          });
        } else {
          this.notifications.warning(this.intl.t('components.hire-package.invalid'));
        }
      }
    }
  });

  _exports.default = _default;
});