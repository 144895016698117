define("front/templates/components/companies/image-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iXthyKt+",
    "block": "{\"symbols\":[\"@id\",\"&default\"],\"statements\":[[10,\"div\"],[15,1,[32,1]],[14,0,\"container paddbox box-reports\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-11\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"\\n        \"],[2,[34,0]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12 col-md-offset-1\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"img-1\"],[15,\"src\",[34,1]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"image\"]}",
    "meta": {
      "moduleName": "front/templates/components/companies/image-section.hbs"
    }
  });

  _exports.default = _default;
});