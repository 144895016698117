define("front/components/modals/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      document.body.classList.add('with-modal');
    },
    willDestroyElement: function willDestroyElement() {
      document.body.classList.remove('with-modal');

      this._super.apply(this, arguments);
    },
    actions: {
      close: function close() {
        this.toggle();
      }
    }
  });

  _exports.default = _default;
});