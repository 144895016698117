define("front/components/company-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="company-detail">
    {{#link-to "companies.index" class="close dark"}}{{/link-to}}
    {{yield}}
    <div class="company-detail--back">
      {{#link-to "companies.index" class="company-detail--back-btn"}}{{t "general.more-about-alfred"}}{{/link-to}}
    </div>
  </div>
  
  */
  {
    "id": "WNrE78zu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"company-detail\"],[12],[2,\"\\n  \"],[6,[37,1],null,[[\"class\",\"route\"],[\"close dark\",\"companies.index\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n  \"],[18,1,null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"company-detail--back\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"class\",\"route\"],[\"company-detail--back-btn\",\"companies.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"general.more-about-alfred\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "meta": {
      "moduleName": "front/components/company-detail.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});