define("front/components/jobs-react/jobs-widget-react", ["exports", "front/react-component", "front/config/environment", "moment", "react-apps"], function (_exports, _reactComponent, _environment, _moment, _reactApps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "frU9MDO7",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/jobs-react/jobs-widget-react.hbs"
    }
  });

  var features = _environment.default.REGION.features;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    gtm: Ember.inject.service(),
    appApi: Ember.inject.service(),
    fb: Ember.inject.service(),
    reactPortal: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;

      (0, _reactApps.renderJobsWidget)(this.element, this.brand, this.brandColor, {
        appApi: this.appApi,
        env: _environment.default.environment,
        intl: this.intl,
        fb: this.fb,
        gtm: this.gtm,
        config: _environment.default,
        moment: _moment.default,
        features: features,
        currentSession: this.currentSession,
        transitionTo: function transitionTo(route, model) {
          if (model) {
            _this.router.transitionTo(route, model);
          } else {
            _this.router.transitionTo(route);
          }
        },
        generateRoute: function generateRoute(route, model) {
          if (model) {
            return _this.router.urlFor(route, model);
          } else {
            return _this.router.urlFor(route);
          }
        }
      });
    }
  }));

  _exports.default = _default;
});