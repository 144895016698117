define("front/models/applicationvideointerview", ["exports", "ember-api-actions", "@ember-data/model"], function (_exports, _emberApiActions, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    msg: (0, _model.attr)('string'),
    posttext: (0, _model.attr)('string'),
    pretext: (0, _model.attr)('string'),
    viquestions: (0, _model.hasMany)('viquestion'),
    started: (0, _emberApiActions.memberAction)({
      path: 'started',
      type: 'patch'
    }),
    upload: (0, _emberApiActions.memberAction)({
      path: 'upload',
      type: 'post'
    })
  });

  _exports.default = _default;
});