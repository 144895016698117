define("front/serializers/application", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    extractMeta: function extractMeta(store, typeClass, payload) {
      if (payload && !payload.meta) {
        var meta = {};

        if (Object.prototype.hasOwnProperty.call(payload, 'totalCount')) {
          meta.count = payload.totalCount;
          delete payload.totalCount;
        }

        if (Object.prototype.hasOwnProperty.call(payload, 'totalPages')) {
          meta.total_pages = payload.totalPages;
          delete payload.totalPages;
        }

        if (Object.prototype.hasOwnProperty.call(payload, 'unread')) {
          meta.unread = payload.unread;
          delete payload.unread;
        }

        return meta;
      }

      return this._super(store, typeClass, payload);
    }
  });

  _exports.default = _default;
});