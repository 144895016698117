define("front/templates/user/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WXqJ3t/A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"user--profile\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"--flex\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"--left\"],[12],[2,\"\\n        \"],[8,\"profile/bio\",[],[[],[]],null],[2,\"\\n        \"],[8,\"profile/about-me\",[],[[],[]],null],[2,\"\\n        \"],[8,\"profile/languages\",[],[[],[]],null],[2,\"\\n        \"],[8,\"profile/attachments\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"--right\"],[12],[2,\"\\n        \"],[8,\"profile/experiences\",[],[[],[]],null],[2,\"\\n        \"],[8,\"profile/educations\",[],[[],[]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"skillCategories\",\"categories\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"profile/profile-skills\",[],[[\"@skillCategories\"],[[32,0,[\"model\",\"skillCategories\",\"categories\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[8,\"profile/recommendations\",[],[[],[]],null],[2,\"\\n        \"],[8,\"profile/links\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"-actions\"],[12],[2,\"\\n      \"],[8,\"profile/actions\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "front/templates/user/profile.hbs"
    }
  });

  _exports.default = _default;
});