define("front/models/viquestion", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    question: (0, _model.attr)('string'),
    timelimit: (0, _model.attr)('number'),
    weight: (0, _model.attr)('number'),
    active: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});