define("front/routes/profile/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    videoManager: Ember.inject.service(),
    model: function model(params) {
      this.set('videoManager.applicationid', params.id); // if (! this.get('fastboot.isFastBoot')) {
      //     if (params.id) {
      //         return this.store.queryRecord('applicationvideointerview', { applicationid: params.id });
      //     } else {
      //         this.transitionTo('profile.jobapplications');
      //     }
      // }
    },
    afterModel: function afterModel(model, transition) {
      if (model && !model.get('viquestions.length')) {
        transition.abort();
        this.transitionTo('user.inbox');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render("profile/video", {
        into: 'application',
        outlet: 'main'
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('shield');
        this.videoManager.stopStream();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('videoDisabled', true);
      controller.set('videoIsSupported', this.videoManager.videoIsSupported());
    }
  });

  _exports.default = _default;
});