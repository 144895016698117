define("front/adapters/_app", ["exports", "front/adapters/authorized", "front/config/environment"], function (_exports, _authorized, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GENERAL_NAMESPACE = _environment.default.GENERAL_NAMESPACE,
      NAMESPACE = _environment.default.NAMESPACE;

  var _default = _authorized.default.extend({
    namespace: Ember.computed('session.isAuthenticated', function () {
      return this.session.isAuthenticated ? GENERAL_NAMESPACE : NAMESPACE;
    })
  });

  _exports.default = _default;
});