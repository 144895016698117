define("front/components/jobs/job-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['job-filter-container'],
    classNameBindings: ['hasSelectedOption:active'],
    selectedOptions: Ember.computed('options.@each.selected', function () {
      return this.options && this.options.filter(function (o) {
        return o.selected;
      });
    }),
    hasSelectedOption: Ember.computed('options.@each.selected', function () {
      return this.options && this.options.toArray().some(function (o) {
        return o.selected;
      });
    }),
    click: function click() {
      this.toggleProperty('filterVisible');
    },
    actions: {
      hideFilter: function hideFilter() {
        this.set('filterVisible');
      },
      clear: function clear() {
        this.options.forEach(function (o) {
          o.set('selected');
        });
        this.set('filterVisible');
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});