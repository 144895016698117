define("front/components/react-portal/react-portal", ["exports", "front/config/environment", "moment", "react-apps", "front/react-component"], function (_exports, _environment, _moment, _reactApps, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "PwebJDmM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/react-portal/react-portal.hbs"
    }
  });

  var features = _environment.default.REGION.features;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    gtm: Ember.inject.service(),
    appApi: Ember.inject.service(),
    fb: Ember.inject.service(),
    reactPortal: Ember.inject.service(),
    job: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;

      (0, _reactApps.renderReactPortal)(this.element, {
        jobManager: this.job,
        appApi: this.appApi,
        env: _environment.default.environment,
        intl: this.intl,
        fb: this.fb,
        gtm: this.gtm,
        config: _environment.default,
        moment: _moment.default,
        features: features,
        currentSession: this.currentSession,
        currentRoute: this.router.currentRoute,
        transitionTo: function transitionTo(route, model) {
          if (model) {
            _this.router.transitionTo(route, model);
          } else {
            _this.router.transitionTo(route);
          }
        },
        generateRoute: function generateRoute(route, model) {
          if (model) {
            return _this.router.urlFor(route, model);
          } else {
            return _this.router.urlFor(route);
          }
        }
      }, this.currentPage, this.id, this.nav, this.type, this.setTitle);
    }
  }));

  _exports.default = _default;
});