define("front/templates/components/dot-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RZwZwmnf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"dot dot_1\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_2\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_3\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_4\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_5\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_6\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_7\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot dot_8\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/components/dot-loader.hbs"
    }
  });

  _exports.default = _default;
});