define("front/models/kennitala", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ssn: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    individual: (0, _model.attr)('string')
  });

  _exports.default = _default;
});