define("front/serializers/link", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var root = 'link';
      data[root] = this.serialize(record, options);
    }
  });

  _exports.default = _default;
});