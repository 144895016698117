define("front/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "atep0GBA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[30,[36,0],[\"cee\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"link\"],[14,\"rel\",\"preload\"],[14,6,\"/img/icons/cs.svg\"],[14,\"as\",\"image\"],[14,4,\"image/svg+xml\"],[12],[13],[2,\"\\n  \"],[10,\"link\"],[14,\"rel\",\"preload\"],[14,6,\"/img/icons/en.svg\"],[14,\"as\",\"image\"],[14,4,\"image/svg+xml\"],[12],[13],[2,\"\\n  \"],[10,\"link\"],[14,\"rel\",\"preload\"],[14,6,\"/img/icons/sk.svg\"],[14,\"as\",\"image\"],[14,4,\"image/svg+xml\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[\"is\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"link\"],[14,\"rel\",\"preload\"],[14,6,\"/img/icons/is.svg\"],[14,\"as\",\"image\"],[14,4,\"image/svg+xml\"],[12],[13],[2,\"\\n  \"],[10,\"link\"],[14,\"rel\",\"preload\"],[14,6,\"/img/icons/en.svg\"],[14,\"as\",\"image\"],[14,4,\"image/svg+xml\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"structuredData\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"script\"],[14,4,\"application/ld+json\"],[12],[2,\"\\n  \"],[1,[32,0,[\"model\",\"structuredData\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"title\"],[12],[1,[32,0,[\"model\",\"title\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"region\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/head.hbs"
    }
  });

  _exports.default = _default;
});