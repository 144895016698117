define("front/services/host-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    subdomain: null,
    init: function init() {
      this._super.apply(this, arguments);

      var hostname = Ember.Location._location.hostname;
      var regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
      var urlParts = regexParse.exec(hostname);
      var name = hostname.replace(urlParts[0], '').slice(0, -1);

      if (name !== 'www') {
        this.set('subdomain', name);
      }
    }
  });

  _exports.default = _default;
});