define("front/components/header/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @hideNav}}
    {{#if this.renderPlaceholder}}
      <div class="header-main--placeholder"></div>
    {{/if}}
    <div id="header-element" class="{{if @fixed "header-main--fixed"}}">
      <Header::PublicNav @background={{@background}}
        @video={{@video}}
        @isBlue={{@isBlue}}>
          {{yield}}
      </Header::PublicNav>
    </div>
  {{/unless}}
  
  */
  {
    "id": "ztlynMWU",
    "block": "{\"symbols\":[\"@fixed\",\"@background\",\"@video\",\"@isBlue\",\"&default\",\"@hideNav\"],\"statements\":[[6,[37,1],[[32,6]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"renderPlaceholder\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"header-main--placeholder\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,1,\"header-element\"],[15,0,[31,[[30,[36,0],[[32,1],\"header-main--fixed\"],null]]]],[12],[2,\"\\n    \"],[8,\"header/public-nav\",[],[[\"@background\",\"@video\",\"@isBlue\"],[[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,5,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "front/components/header/main.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    stickyNav: true,
    renderPlaceholder: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.stickyNav) {
        this.stickyClass = function () {
          if (_this._isVisibleInViewport(_this.element)) {
            _this.set('renderPlaceholder');

            document.querySelector('#sticky').classList.remove('sticky-nav-container');
          } else {
            _this.set('renderPlaceholder', true);

            document.querySelector('#sticky').classList.add('sticky-nav-container');
          }
        };

        window.addEventListener('scroll', this.stickyClass, false);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.stickyClass) {
        window.removeEventListener('scroll', this.stickyClass);
      }

      this._super.apply(this, arguments);
    },
    _isVisibleInViewport: function _isVisibleInViewport(elem) {
      if (elem) {
        var y = elem.offsetTop;
        var height = elem.offsetHeight;

        while (elem = elem.offsetParent) {
          y += elem.offsetTop;
        }

        var maxHeight = y + height;
        return y < window.pageYOffset + window.innerHeight && maxHeight >= window.pageYOffset;
      }
    }
  }));

  _exports.default = _default;
});