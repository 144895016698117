define("front/components/cookies/cookies-logout", ["exports", "front/components/modals/modal", "ember-local-storage"], function (_exports, _modal, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    profile: Ember.computed.alias('currentSession.profile'),
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    toggle: function toggle() {
      this.profile.set('cookies.options', true);
      this.profile.saveCookies(this.profile.cookies);
    }
  });

  _exports.default = _default;
});