define("front/serializers/cookies", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractId: function extractId() {
      return 'COOKIES';
    }
  });

  _exports.default = _default;
});