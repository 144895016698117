define("front/templates/companies/remoteinterviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ArJfpYt6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"company-detail\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"companies/remote-interviews\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/companies/remoteinterviews.hbs"
    }
  });

  _exports.default = _default;
});