define("front/components/companies/visir", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Companies::ImageSection
    @id="visir"
    @darker={{@darker}}
    @title={{t "companies.visir.title"}}
    @image="/img/visir.png">
    <p>
      {{{t "companies.visir.p1"}}}
    </p>
    <p>
      {{{t "companies.visir.p2"}}}
    </p>
  </Companies::ImageSection>
  
  */
  {
    "id": "8alXHXeq",
    "block": "{\"symbols\":[\"@darker\"],\"statements\":[[8,\"companies/image-section\",[],[[\"@id\",\"@darker\",\"@title\",\"@image\"],[\"visir\",[32,1],[30,[36,0],[\"companies.visir.title\"],null],\"/img/visir.png\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[2,[30,[36,0],[\"companies.visir.p1\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[2,[30,[36,0],[\"companies.visir.p2\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "front/components/companies/visir.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});