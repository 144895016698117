define("front/models/jobapplication", ["exports", "ember-api-actions", "@ember-data/model"], function (_exports, _emberApiActions, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    applicationType: (0, _model.attr)('string'),
    attachments: (0, _model.hasMany)('attachment'),
    questions: (0, _model.hasMany)('question'),
    brandName: (0, _model.attr)('string'),
    cover: (0, _model.attr)('string'),
    jobId: (0, _model.attr)('number'),
    lastChange: (0, _model.attr)('string'),
    lastEventType: (0, _model.attr)(),
    logo: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    colorCode: (0, _model.attr)('string'),
    statusLabel: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    unreadMessages: (0, _model.attr)('number'),
    canBeArchived: (0, _model.attr)('boolean'),
    canBeWithdrawn: (0, _model.attr)('boolean'),
    showChat: (0, _model.attr)('boolean'),
    profileIsAllowedToSendMessage: (0, _model.attr)('boolean'),
    category: (0, _model.attr)('string'),
    unread: (0, _model.attr)('number'),
    hasinterview: (0, _model.attr)('boolean'),
    hasunread: (0, _model.attr)('boolean'),
    open: (0, _model.attr)('boolean'),
    submitted: (0, _model.attr)('string'),
    updated: (0, _model.attr)('string'),
    eventDates: (0, _model.hasMany)('event-date'),
    withdraw: (0, _emberApiActions.memberAction)({
      path: 'withdraw',
      type: 'patch'
    }),
    canChat: Ember.computed('profileIsAllowedToSendMessage', function () {
      return this.profileIsAllowedToSendMessage;
    }),
    hideChat: Ember.computed('status', function () {
      return this.status === 'CANCELED' || this.status === 'REJECTED';
    }),
    sendMessage: (0, _emberApiActions.memberAction)({
      path: 'message',
      type: 'post'
    }),
    derivedStatus: Ember.computed('job.adstatus', 'status', function () {
      if (this.status != 'ACTIVE' && this.status != 'REVIEW' && this.status != 'INTERVIEW' && this.status != 'VIDEOINTERVIEW') {
        return this.status;
      }

      if (this.get('job.adstatus') == 'Ended' || this.get('job.adstatus') == 'Archived') {
        return 'ENDED';
      }

      return this.status;
    }),
    isActive: Ember.computed('status', function () {
      return this.status == 'ACTIVE' || this.status == 'INTERVIEW' || this.status == 'VIDEOINTERVIEW' || this.status == 'REVIEW';
    })
  });

  _exports.default = _default;
});