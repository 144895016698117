define("front/components/companies/alfred-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Companies::ImageSection
    @title={{t "companies.reports.h2"}}
    @image={{region-asset "company_reports.jpg"}}>
    <p>
      {{t "companies.reports.p1"}}
    </p>
    <p>
      {{t "companies.reports.p2"}}
    </p>
    <p>
      {{t "companies.reports.p3" htmlSafe=true}}
    </p>
  </Companies::ImageSection>
  
  */
  {
    "id": "s4NvLGgU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"companies/image-section\",[],[[\"@title\",\"@image\"],[[30,[36,0],[\"companies.reports.h2\"],null],[30,[36,1],[\"company_reports.jpg\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"companies.reports.p1\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"companies.reports.p2\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"companies.reports.p3\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"region-asset\"]}",
    "meta": {
      "moduleName": "front/components/companies/alfred-features.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});