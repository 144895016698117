define("front/components/video/video-recorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { observer } from '@ember/object';
  var _default = Ember.Component.extend({
    // checkStream: observer('videoManager.stream', function() {
    //     if (this.get('videoManager.stream')) {
    //         this.get('videoManager').setRecorder();
    //     }
    // }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.delay) {
        this.timer = setInterval(function () {
          _this.set('delay', _this.delay - 1);

          if (_this.delay === 0) {
            clearInterval(_this.timer);

            _this.startRecording();
          }
        }, 1000);
      }
    },
    startRecording: function startRecording() {
      this.videoManager.startRecording();
    }
  });

  _exports.default = _default;
});