define("front/components/profile/profile-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="profile-card">
    <div class="--header">
      <div class="--title">
        {{@title}}
      </div>
      <div class="--actions">
        {{#if @action}}
          <button class="--action"
            {{on "click" (fn @action)}}>{{@actionName}}</button>
        {{/if}}
      </div>
    </div>
    <div class="profile-card--content">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "eE8RhwnJ",
    "block": "{\"symbols\":[\"@action\",\"@actionName\",\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"profile-card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"--header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"--title\"],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"--actions\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"--action\"],[4,[38,1],[\"click\",[30,[36,0],[[32,1]],null]],null],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"profile-card--content\"],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "front/components/profile/profile-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});