define("front/serializers/profile-status", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      properties: {
        embedded: 'always'
      }
    },
    extractId: function extractId() {
      return 'STATUS';
    }
  });

  _exports.default = _default;
});