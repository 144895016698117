define("front/models/event", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    date: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    sender: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    initials: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    systemMessage: (0, _model.attr)('boolean'),
    videoInterview: (0, _model.attr)(),
    hirePackage: (0, _model.attr)(),
    attachment: (0, _model.belongsTo)('event-attachment'),
    hasMessage: Ember.computed('type', function () {
      return this.description && (this.type == 'MESSAGE' || this.type == 'CALINVITE');
    }),
    style: Ember.computed('type', 'sender', function () {
      if (this.sender === 'APP' && this.type === 'MESSAGE') {
        return 'APPMESSAGE';
      }

      if (this.sender === 'APP') {
        return 'APPINFO';
      }

      if (this.sender === 'WEB' && this.type === 'MESSAGE') {
        return 'WEBMESSAGE';
      }

      if (this.type === 'CALACCEPT' && this.type === 'CALCANCEL' && this.type === 'CALDECLINE' && this.type === 'CALINVITE' && this.type === 'CALREQUESTNEW' && this.type === 'USERCALREQUESTNEW') {
        return 'ACTION';
      }

      return 'WEBINFO';
    })
  });

  _exports.default = _default;
});