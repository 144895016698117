define("front/components/layout/space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['layout-space'],
    styleString: Ember.computed('top', 'right', 'bottom', 'left', 'all', 'horizontal', 'vertical', 'textCenter', function () {
      var styleString = '';

      if (this.all) {
        styleString += "margin: ".concat(this.all, "px;");
      }

      if (this.horizontal) {
        styleString += "margin-left: ".concat(this.horizontal, "px;");
        styleString += "margin-right: ".concat(this.horizontal, "px;");
      }

      if (this.vertical) {
        styleString += "margin-top: ".concat(this.vertical, "px;");
        styleString += "margin-bottom: ".concat(this.vertical, "px;");
      }

      if (this.top) {
        styleString += "margin-top: ".concat(this.top, "px;");
      }

      if (this.right) {
        styleString += "margin-right: ".concat(this.right, "px;");
      }

      if (this.right) {
        styleString += "margin-bottom: ".concat(this.bottom, "px;");
      }

      if (this.left) {
        styleString += "margin-left: ".concat(this.left, "px;");
      }

      if (this.bottom) {
        styleString += "margin-bottom: ".concat(this.bottom, "px");
      }

      if (this.textCenter) {
        styleString += "text-align: center";
      }

      return styleString;
    })
  });

  _exports.default = _default;
});