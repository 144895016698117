define("front/components/mobile-banner", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var custom = _environment.default.REGION.meta.custom;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['mobile-banner'],
    init: function init() {
      this._super.apply(this, arguments);

      this.appId = custom.findBy('property', 'al:android:package').content;
    },
    actions: {
      close: function close() {
        this.$().slideToggle('slow');
      }
    }
  });

  _exports.default = _default;
});