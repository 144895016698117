define("front/models/category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    count: (0, _model.attr)('number'),
    image: (0, _model.attr)('string', {
      defaultValue: '/img/header.jpg'
    }),
    active: (0, _model.attr)('boolean'),
    catid: (0, _model.attr)('number'),
    slug: (0, _model.attr)('string'),
    header: (0, _model.attr)('string'),
    jobTags: (0, _model.hasMany)('jobtag'),
    selectedTags: Ember.computed.filterBy('jobTags', 'selected', true),
    checkedAll: Ember.computed('jobTags', 'selectedTags', function () {
      return this.jobTags.length === this.selectedTags.length;
    })
  });

  _exports.default = _default;
});