define("front/components/company-profile/achievements-list", ["exports", "alfred-company-profile/components/company-profile/achievements-list"], function (_exports, _achievementsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _achievementsList.default;
    }
  });
});