define("front/services/notification-manager", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    apiManager: Ember.inject.service(),
    apiFront: Ember.inject.service(),
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    announcements: [],
    getNotificationCount: function getNotificationCount() {
      var _this = this;

      if (this.get('storage.profileid')) {
        return;
        this.apiManager.get("/applications/unreadcount").then(function (response) {
          _this.set('count', response.count);
        });
      }
    },
    runNotifications: function runNotifications(openProfile) {
      var _this2 = this;

      this.set('openProfile', openProfile);

      if (!this.intervalSet) {
        this.interval = setInterval(function () {
          _this2.set('intervalSet', true);

          _this2.getNotificationCount();
        }, 30000);
      }
    },
    runAnnouncements: function runAnnouncements(profile) {
      var _this3 = this;

      if (profile) {
        this.apiFront.get("/profiles/".concat(profile.id, "/profileannouncements")).then(function (res) {
          _this3.set('announcements', res.profileAnnouncements);
        });
      }
    },
    stopNotifications: function stopNotifications() {
      clearInterval(this.interval);
    },
    clearCount: function clearCount() {
      this.set('count', 0);
    },
    popAnnouncement: function popAnnouncement() {
      var newAnnouncements = _toConsumableArray(this.announcements);

      newAnnouncements.pop();
      Ember.set(this, 'announcements', newAnnouncements);
      console.log(this.announcements);
    }
  });

  _exports.default = _default;
});