define("front/templates/components/modals/profile-terms-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yh6LkD+y",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@overlayClass\"],[\"modal-popup termsModal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"logo\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Alfreð er öruggur\"],[13],[2,\"\\n\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[2,\"Við viljum tryggja öryggi upplýsinga þinna og höfum við því sett saman persónuverndarstefnu okkar sem eru í samræmi við ný lög um persónuvernd. Frekari upplýsingar um stefnu okkar má finna \"],[10,\"a\"],[14,0,\"green\"],[15,6,[30,[36,0],[\"privacy\"],null]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[10,\"strong\"],[12],[2,\"hér\"],[13],[13],[2,\".\"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-green upper\"],[4,[38,1],[[32,0],\"agree\"],null],[12],[2,\"Halda áfram\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get-link\",\"action\"]}",
    "meta": {
      "moduleName": "front/templates/components/modals/profile-terms-new.hbs"
    }
  });

  _exports.default = _default;
});