define("front/templates/components/cookies/cookies-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zCmPzkK8",
    "block": "{\"symbols\":[\"@logout\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup termsModal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"logo\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n\\n      \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.cookies_logout.title\"],null]],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.cookies_logout.required\"],[[\"htmlSafe\"],[true]]]],[10,\"br\"],[12],[13],[10,\"a\"],[15,6,[31,[[30,[36,3],[\"cookies\"],null],\"?lang=\",[34,2,[\"lang\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[10,\"strong\"],[12],[1,[30,[36,1],[\"components.cookies_logout.read_more\"],null]],[13],[13],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-md-11\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-red upper\"],[4,[38,0],[[32,0],[32,1]],null],[12],[1,[30,[36,1],[\"components.cookies_logout.log_out\"],null]],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-md-13\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-brd-gray upper\"],[4,[38,0],[[32,0],[32,0,[\"toggle\"]]],null],[12],[1,[30,[36,1],[\"components.cookies_logout.accept\"],null]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"storage\",\"get-link\"]}",
    "meta": {
      "moduleName": "front/templates/components/cookies/cookies-logout.hbs"
    }
  });

  _exports.default = _default;
});