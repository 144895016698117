define("front/components/apply/login-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Apply::ApplicationCard>
    <div class="cover">
      <div class="images">
        <div class="image thumb"
          style={{html-safe bg=@job.brand.logo}}></div>
      </div>
      <p>{{t "components.job_apply.header" brand=@job.brand.name}}</p>
    </div>
    <ul>
      <li>
        <div class="icon anon"></div>
        <p>
          <div><strong>{{t "components.job_apply.apply_login.title"}}</strong></div>
          {{t "components.job_apply.apply_login.text"}}
        </p>
      </li>
    </ul>
    <div class="actions">
      <button {{on "click" @close}}>{{t "string.close"}}</button>
      <button class="blue"
        {{on "click" @toggleLogin}}>{{t "components.job_apply.apply_login.login"}}</button>
    </div>
  </Apply::ApplicationCard>
  
  */
  {
    "id": "o9883ao/",
    "block": "{\"symbols\":[\"@job\",\"@close\",\"@toggleLogin\"],\"statements\":[[8,\"apply/application-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"cover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"images\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"image thumb\"],[15,5,[30,[36,0],null,[[\"bg\"],[[32,1,[\"brand\",\"logo\"]]]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.job_apply.header\"],[[\"brand\"],[[32,1,[\"brand\",\"name\"]]]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"icon anon\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[10,\"strong\"],[12],[1,[30,[36,1],[\"components.job_apply.apply_login.title\"],null]],[13],[13],[2,\"\\n        \"],[1,[30,[36,1],[\"components.job_apply.apply_login.text\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[4,[38,2],[\"click\",[32,2]],null],[12],[1,[30,[36,1],[\"string.close\"],null]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"blue\"],[4,[38,2],[\"click\",[32,3]],null],[12],[1,[30,[36,1],[\"components.job_apply.apply_login.login\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"t\",\"on\"]}",
    "meta": {
      "moduleName": "front/components/apply/login-screen.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});