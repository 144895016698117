define("front/components/company-profile/company-profile-header", ["exports", "alfred-company-profile/components/company-profile/company-profile-header"], function (_exports, _companyProfileHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _companyProfileHeader.default;
    }
  });
});