define("front/components/skills/empty-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="empty-screen">
    <img src="/img/icons/alfred-icon-empty.svg" alt="" />
    <div class="empty-screen--title">
      {{@title}}
    </div>
    <div class="empty-screen--content">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "KwR5eumE",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"empty-screen\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/img/icons/alfred-icon-empty.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"empty-screen--title\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"empty-screen--content\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/skills/empty-screen.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});