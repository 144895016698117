define("front/serializers/profile", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      attachments: {
        embedded: 'always'
      },
      experiences: {
        embedded: 'always'
      },
      links: {
        embedded: 'always'
      },
      educations: {
        embedded: 'always'
      },
      languages: {
        embedded: 'always'
      },
      notifications: {
        embedded: 'always'
      },
      profileRecommendations: {
        embedded: 'always'
      },
      profileStatus: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});