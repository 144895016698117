define("front/models/brand", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    logo: (0, _model.attr)('string'),
    web: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    facebook: (0, _model.attr)('string'),
    twitter: (0, _model.attr)('string'),
    instagram: (0, _model.attr)('string'),
    cover: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    childBrands: (0, _model.attr)(),
    address: (0, _model.attr)(),
    generalApplicationJob: (0, _model.attr)(),
    domain: (0, _emberApiActions.collectionAction)({
      path: 'domain',
      type: 'get'
    }),
    bodybr: Ember.computed('body', function () {
      if (this.body) {
        return Ember.String.htmlSafe(this.body.replace(/\n/g, '<br>'));
      }
    })
  });

  _exports.default = _default;
});