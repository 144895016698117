define("front/models/job", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    applied: (0, _model.attr)('boolean'),
    expired: (0, _model.attr)('boolean'),
    verificationRequired: (0, _model.attr)('boolean'),
    // e-sign verification
    slug: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    start: (0, _model.attr)('moment'),
    deadline: (0, _model.attr)('date-without-time'),
    created: (0, _model.attr)('moment'),
    description: (0, _model.attr)('string'),
    applyEmail: (0, _model.attr)('string'),
    applyWeb: (0, _model.attr)('string'),
    jobaddress: (0, _model.attr)('string'),
    adstatus: (0, _model.attr)('string'),
    jobType: (0, _model.attr)('string'),
    bodyhtml: (0, _model.attr)('string'),
    applyType: (0, _model.attr)('string'),
    published: (0, _model.attr)('moment'),
    applicationsEnabled: (0, _model.attr)('boolean'),
    jobBenefits: (0, _model.attr)(),
    jobQualifications: (0, _model.attr)(),
    jobResponsibilities: (0, _model.attr)(),
    jobCompensations: (0, _model.attr)(),
    subCategories: (0, _model.attr)(),
    footer: (0, _model.attr)('string'),
    read: (0, _model.attr)('boolean'),
    language: (0, _model.attr)('string'),
    originalLanguage: (0, _model.attr)('string'),
    translated: (0, _model.attr)('boolean'),
    favorite: (0, _model.attr)('boolean'),
    applicationId: (0, _model.attr)('number'),
    address: (0, _model.attr)(),
    // this used to be a model, but we are not storing it in the db anymore.
    categories: (0, _model.attr)(),
    languages: (0, _model.attr)(),
    adtype: (0, _model.belongsTo)('adtype'),
    brand: (0, _model.belongsTo)('brand'),
    jobstatus: (0, _model.belongsTo)('jobstatus'),
    skills: (0, _model.hasMany)('skill'),
    addresses: (0, _model.attr)(),
    similarjobs: (0, _emberApiActions.memberAction)({
      path: 'similar',
      type: 'get'
    }),
    otherjobs: (0, _emberApiActions.memberAction)({
      path: 'brand/other',
      type: 'get'
    }),
    sendmail: (0, _emberApiActions.memberAction)({
      path: 'send',
      type: 'post'
    }),
    questions: (0, _emberApiActions.memberAction)({
      path: 'questions',
      type: 'get'
    }),
    increasecta: (0, _emberApiActions.memberAction)({
      path: 'webcta',
      type: 'patch'
    }),
    descriptionShort: Ember.computed('description', function () {
      var str;

      if (this.description) {
        this.description.replace(/<(?:.|\n)*?>/gm, '');

        if (str.length > 280) {
          str = str.substr(0, 280);
          str = str.substr(0, Math.min(str.length, str.lastIndexOf(' '))) + "\u2026";
        }
      }

      return str;
    })
  });

  _exports.default = _default;
});