define("front/services/api-manager", ["exports", "front/services/api-front", "front/config/environment"], function (_exports, _apiFront, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NAMESPACE = _environment.default.NAMESPACE;

  var _default = _apiFront.default.extend({
    namespace: "/".concat(NAMESPACE)
  });

  _exports.default = _default;
});