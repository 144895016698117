define("front/templates/components/jobs/job-item-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yf+RBq0s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-lg-6 col-md-8 col-sm-12 job-item-placeholder\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"placeholder-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"logo\"],[12],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[10,\"div\"],[14,0,\"band placeholder\"],[12],[13],[13],[2,\"\\n    \"],[10,\"h5\"],[12],[10,\"div\"],[14,0,\"band placeholder\"],[12],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/components/jobs/job-item-placeholder.hbs"
    }
  });

  _exports.default = _default;
});