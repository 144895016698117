define("front/templates/components/video/upload-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gYpmFd9E",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"progress-bar \",[30,[36,2],[[30,[36,1],[[35,0],100],null],\"done\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"progress\"],[15,5,[30,[36,4],[[30,[36,3],[\"width:\",[35,0],\"%;\"],null]],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[35,0],100],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"done\"],[12],[2,\"Vídeóviðtali hlaðið upp.\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"Sendi vídeóviðtalið. Vinsamlegast bíðið.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"progress\",\"gte\",\"if\",\"concat\",\"html-safe\"]}",
    "meta": {
      "moduleName": "front/templates/components/video/upload-progress.hbs"
    }
  });

  _exports.default = _default;
});