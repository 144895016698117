define("front/models/experience", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    company: (0, _model.attr)('string'),
    fromdate: (0, _model.attr)('string'),
    // YYYY-MM-DD
    todate: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    current: (0, _model.attr)('boolean'),
    timePeriod: Ember.computed('fromdate', 'todate', 'current', function () {
      var period = (0, _moment.default)(this.fromdate).format('MMM YYYY');

      if (this.fromdate) {
        period += ' - ';
      }

      if (this.todate && !this.current) {
        period += (0, _moment.default)(this.todate).format('MMM YYYY');
      }

      return period;
    })
  });

  _exports.default = _default;
});