define("front/templates/components/video/video-recorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Es7COpAh",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"video/video-stream\",[],[[\"@videoManager\",\"@clean\"],[[34,0],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"videoManager\"]}",
    "meta": {
      "moduleName": "front/templates/components/video/video-recorder.hbs"
    }
  });

  _exports.default = _default;
});