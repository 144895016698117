define("front/components/detail/detail-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="detail-list">
    <div class="detail-list--title">{{@title}}</div>
    <div class="detail-list--content">
      {{#each @items as |item|}}
        <div class="detail-list--item">{{trim-spaces item.description}}</div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "0z5y7aOg",
    "block": "{\"symbols\":[\"item\",\"@title\",\"@items\"],\"statements\":[[10,\"div\"],[14,0,\"detail-list\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"detail-list--title\"],[12],[1,[32,2]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"detail-list--content\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"detail-list--item\"],[12],[1,[30,[36,0],[[32,1,[\"description\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"trim-spaces\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "front/components/detail/detail-list.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});