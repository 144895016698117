define("front/controllers/profile/video/done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    videoManager: Ember.inject.service(),
    isDemo: Ember.computed.equal('model.id', '0'),
    name: Ember.computed('currentSession.profile.name', function () {
      return this.get('currentSession.profile.name').split(' ')[0];
    }),
    actions: {}
  });

  _exports.default = _default;
});