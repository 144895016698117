define("front/components/profile/_edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    click: function click() {
      this.toggleProperty('editModal');
    },
    actions: {
      remove: function remove(model) {
        model.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});