define("front/components/detail/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="detail-breadcrumbs">
    {{#if @category}}
      <li><LinkTo @route="category" @model={{@category.id}}>{{@category.name}}</LinkTo></li>
    {{/if}}
    <li>{{@title}}</li>
  </ul>
  
  */
  {
    "id": "+Uu/+P6O",
    "block": "{\"symbols\":[\"@category\",\"@title\"],\"statements\":[[10,\"ul\"],[14,0,\"detail-breadcrumbs\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"category\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"li\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "front/components/detail/breadcrumbs.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});