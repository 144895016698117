define("front/components/profile/application-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['application-message', 'row'],
    classNameBindings: ['message.sender', 'message.systemMessage::MESSAGE'],
    apiManager: Ember.inject.service(),
    closeInviteModals: function closeInviteModals() {
      this.set('acceptInvitation');
      this.set('declineInvitation');
      this.set('rescheduleInvitation');
    },
    sendReply: function sendReply(type, message) {
      var _this = this;

      this.apiManager.patch("/applications/".concat(this.application.id, "/event/").concat(this.message.id, "/response"), {
        response: type,
        message: message
      }).then(function () {
        _this.closeInviteModals();

        _this.refresh();
      });
    },
    actions: {
      sendInviteAccept: function sendInviteAccept(message) {
        this.sendReply('CALACCEPT', message);
      },
      sendInviteDecline: function sendInviteDecline(message) {
        this.sendReply('CALDECLINE', message);
      },
      sendInviteReschedule: function sendInviteReschedule(message) {
        this.sendReply('CALREQUESTNEW', message);
      }
    }
  });

  _exports.default = _default;
});