define("front/adapters/applicationmessage", ["exports", "front/adapters/authorized"], function (_exports, _authorized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return this._super.apply(this, arguments) + '?applicationid=' + snapshot.adapterOptions.applicationid;
    }
  });

  _exports.default = _default;
});