define("front/templates/components/jobs/christmasjobs-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yCXX++ST",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[\"is\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"christmasjobs-btn\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"christmasjobs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"general.open-christmasjobs\"],null]],[2,\" \"],[10,\"span\"],[14,0,\"icon tree\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"region\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/components/jobs/christmasjobs-btn.hbs"
    }
  });

  _exports.default = _default;
});