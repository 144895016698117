define("front/storages/page", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return {
        translate: false,
        translateNever: false
      };
    }
  });
  var _default = Storage;
  _exports.default = _default;
});