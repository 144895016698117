define("front/controllers/about", ["exports", "front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var links = _environment.default.REGION.links;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    links: links,
    actions: {}
  });

  _exports.default = _default;
});