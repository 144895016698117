define("front/templates/components/modals/application-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1DzFAznN",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup successModal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"mob-close dark\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n    \"],[10,\"img\"],[14,\"src\",\"/img/alfred.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.application_success.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"components.application_success.p1\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"components.application_success.p2\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"button\"],[24,0,\"btn btn-brd-gray\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"components.application_success.confirm\"],null]],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/modals/application-success.hbs"
    }
  });

  _exports.default = _default;
});