define("front/components/jobs/filter-tags-modal", ["exports", "front/components/modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    allTags: Ember.computed('categories', function () {
      var allTags = [];
      this.categories.forEach(function (category) {
        allTags = allTags.concat(category.get('jobTags').toArray());
      });
      return allTags;
    }),
    searchTags: Ember.computed('allTags', 'query.length', function () {
      var _this = this;

      if (this.query.length > 2) {
        return this.allTags.filter(function (tag) {
          return tag.get('name').flatten().search(_this.query.flatten()) >= 0;
        });
      }

      return;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('category', this.selectedCategory.jobTags.length ? this.selectedCategory : this.categories[1]);
      var category = this.categories.find(function (category) {
        return category.selectedTags.length;
      });

      if (category) {
        return this.set('category', category);
      }

      if (this.selectedCategory.jobTags.length) {
        Ember.run.next(this, function () {
          this.category.toggleProperty('checkAll');
          this.category.jobTags.forEach(function (jobtag) {
            jobtag.set('selected', true);
          });
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.next(this, function () {
        var menu = document.getElementById('catmenu');
        var item = document.getElementById(this.category.id);
        menu.scrollTop = item.offsetTop - menu.offsetHeight / 2 - item.offsetHeight / 2;
      });
    },
    apply: function apply() {
      var slug = this.categories.firstObject.slug;

      if (this.tags.length === this.selectedCategory.selectedTags.length) {
        slug = this.selectedCategory.slug;
      }

      this.refresh(slug);
      this.toggle();
    },
    checkAll: function checkAll() {
      var _this2 = this;

      this.category.toggleProperty('checkAll');
      this.category.jobTags.forEach(function (jobtag) {
        if (_this2.category.checkAll) {
          return jobtag.set('selected', true);
        }

        return jobtag.set('selected');
      });
    },
    clearAndClose: function clearAndClose() {
      this.clearJobtags();
      this.apply();
    }
  });

  _exports.default = _default;
});