define("front/components/header/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @count}}
    <div class="--notification {{@color}}">
      {{#if (gt @count 99)}}
        99+
      {{else}}
        {{@count}}
      {{/if}}
    </div>
  {{/if}}
  
  */
  {
    "id": "XBGK9/YA",
    "block": "{\"symbols\":[\"@count\",\"@color\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"--notification \",[32,2]]]],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1],99],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      99+\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "front/components/header/notification.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});