define("front/components/jobs-react/jobs-react", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "Sq5dWzlg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/jobs-react/jobs-react.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    reactPortal: Ember.inject.service(),
    didRender: function didRender() {
      this.reactPortal.currentPage = 'jobs';
    },
    willDestroyElement: function willDestroyElement() {
      this.reactPortal.currentPage = null;
    }
  }));

  _exports.default = _default;
});