define("front/components/profile/hire-question", ["exports", "ember-basic-dropdown/utils/calculate-position", "front/config/environment"], function (_exports, _calculatePosition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var userSnnLength = _environment.default.REGION.userSnnLength;

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    classNames: ['hire-question'],
    classNameBindings: ['isValid:valid'],
    tagName: 'li',
    isValid: Ember.computed('question.{type,answer,bank,hb,account,option.id,required}', function () {
      var isValid;

      if (this.question && !this.question.required) {
        this.set('question.valid', true);
        return true;
      }

      if (this.question.type === 'IS_SSN') {
        isValid = this.question.answer && this.question.answer.length === userSnnLength;
      }

      if (this.question.type === 'FREE_TEXT') {
        isValid = this.question.answer && this.question.answer.length > 3;
      }

      if (this.question.type === 'SEARCHABLE_SINGLE_OPTION' || this.question.type === 'SINGLE_OPTION') {
        isValid = this.question.option && !!this.question.option.id;
      }

      if (this.question.type === 'IS_BANK') {
        isValid = this.question.bank && this.question.bank.length > 2 && this.question.hb && this.question.hb.length === 2 && this.question.account && this.question.account.length > 2;
      }

      this.set('question.valid', isValid);
      return isValid;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var verification = this.get('currentSession.profile.verification');

      if (this.question && this.question.key === 'ICELANDIC_SSN' && !this.question.answer && verification) {
        Ember.set(this, 'question.answer', verification.ssn);
      }
    },
    calculatePosition: function calculatePosition() {
      var originalValue = _calculatePosition.calculateWormholedPosition.apply(void 0, arguments);

      if (window.innerWidth < 768) {
        return originalValue;
      }

      originalValue.style['min-width'] = '390px';
      originalValue.style['left'] = originalValue.style['left'] - 60;
      return originalValue;
    },
    clearOption: function clearOption(question) {
      this.set('question.option');
    },
    update: function update(unmasked) {
      Ember.set(this, 'question.answer', unmasked);
    },
    updateBank: function updateBank(unmasked) {
      Ember.set(this, 'question.bank', unmasked);
    },
    updateHb: function updateHb(unmasked) {
      Ember.set(this, 'question.hb', unmasked);
    },
    updateAccount: function updateAccount(unmasked) {
      Ember.set(this, 'question.account', unmasked);
    }
  }, (_applyDecoratedDescriptor(_obj, "calculatePosition", [_dec], Object.getOwnPropertyDescriptor(_obj, "calculatePosition"), _obj), _applyDecoratedDescriptor(_obj, "clearOption", [_dec2], Object.getOwnPropertyDescriptor(_obj, "clearOption"), _obj), _applyDecoratedDescriptor(_obj, "update", [_dec3], Object.getOwnPropertyDescriptor(_obj, "update"), _obj), _applyDecoratedDescriptor(_obj, "updateBank", [_dec4], Object.getOwnPropertyDescriptor(_obj, "updateBank"), _obj), _applyDecoratedDescriptor(_obj, "updateHb", [_dec5], Object.getOwnPropertyDescriptor(_obj, "updateHb"), _obj), _applyDecoratedDescriptor(_obj, "updateAccount", [_dec6], Object.getOwnPropertyDescriptor(_obj, "updateAccount"), _obj)), _obj)));

  _exports.default = _default;
});