define("front/components/user/inbox/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="--application {{if @application.unread "unread"}}">
    <LinkTo @route="user.inbox.detail"
      @model={{@application.id}}>
      <div class="logo">
        <img src={{imgix @application.logo w=100}}
          alt="">
      </div>
      <div class="body hidden-xs">
        <div class="status" style={{html-safe (concat "color: " @application.colorCode)}}>
          {{@application.statusLabel}}
        </div>
        <div class="title">{{@application.title}}</div>
        <div class="brand">{{@application.brandName}}</div>
      </div>
      <div class="other hidden-xs">
        {{#if @application.unreadMessages}}
          <div class="badge">{{@application.unreadMessages}}</div>
        {{else}}
          <small>{{from-now @application.lastChange}}</small>
        {{/if}}
      </div>
    </LinkTo>
  </div>
  
  */
  {
    "id": "qa9ocvJf",
    "block": "{\"symbols\":[\"@application\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"--application \",[30,[36,1],[[32,1,[\"unread\"]],\"unread\"],null]]]],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"user.inbox.detail\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"logo\"],[12],[2,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,[36,2],[[32,1,[\"logo\"]]],[[\"w\"],[100]]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"body hidden-xs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"status\"],[15,5,[30,[36,4],[[30,[36,3],[\"color: \",[32,1,[\"colorCode\"]]],null]],null]],[12],[2,\"\\n        \"],[1,[32,1,[\"statusLabel\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"brand\"],[12],[1,[32,1,[\"brandName\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"other hidden-xs\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"unreadMessages\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"badge\"],[12],[1,[32,1,[\"unreadMessages\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"small\"],[12],[1,[30,[36,0],[[32,1,[\"lastChange\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"from-now\",\"if\",\"imgix\",\"concat\",\"html-safe\"]}",
    "meta": {
      "moduleName": "front/components/user/inbox/application.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});