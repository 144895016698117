define("front/routes/user/favorites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        jobs: this.infinity.model('job', {
          perPage: 30,
          startingPage: 1,
          perPageParam: 'size',
          saved: true
        })
      }).then(function (res) {
        var applicationCntrl = _this.controllerFor('application');

        applicationCntrl.set('hideLoader');
        return res;
      });
    }
  });

  _exports.default = _default;
});