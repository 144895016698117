define("front/components/pages-react/pages-react", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if title}}
    {{title title}}
  {{/if}}
  
  {{yield}}
  
  */
  {
    "id": "lVRJwKMV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"front/components/pages-react/pages-react.hbs\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\",\"if\"]}",
    "meta": {
      "moduleName": "front/components/pages-react/pages-react.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    reactPortal: Ember.inject.service(),
    didRender: function didRender() {
      var _this = this;

      this.reactPortal.currentPage = 'pages';
      this.reactPortal.id = this.id;
      this.reactPortal.nav = this.nav;
      this.reactPortal.type = this.type;

      this.reactPortal.setTitle = function (title) {
        _this.set('title', title);
      };
    },
    willDestroyElement: function willDestroyElement() {
      this.reactPortal.currentPage = null;
      this.reactPortal.id = null;
      this.reactPortal.nav = null;
      this.reactPortal.type = null;
      this.reactPortal.setTitle = null;
    }
  }));

  _exports.default = _default;
});