define("front/templates/components/env-strip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9JVcSSYg",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],null,null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      DEV\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      STAGE\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dev\",\"if\",\"region\",\"show\"]}",
    "meta": {
      "moduleName": "front/templates/components/env-strip.hbs"
    }
  });

  _exports.default = _default;
});