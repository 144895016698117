define("front/templates/profile/video/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UHxdx7Jy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[2,\"Vafrar í boði\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Því miður er aðeins hægt að taka vídeóviðtöl með nýjustu útgáfunni af Chrome\"],[10,\"br\"],[12],[13],[2,\"eða með Alfreð appinu í snjallsímanum. Kíktu á appið \"],[8,\"link-to\",[],[[\"@route\"],[\"app\"]],[[\"default\"],[{\"statements\":[[2,\"hér\"]],\"parameters\":[]}]]],[2,\".\"],[10,\"br\"],[12],[13],[2,\"Ef þú ert að nota Chrome þá vinsamlegast endurræsið tölvuna fyrir nýjustu\\n    útgáfuna.\"],[10,\"br\"],[12],[13],[2,\"Ef þú ert að nota annan vafra þá getur þú sótt Chrome með því að smella á lógóið hér fyrir neðan.\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"browsers\"],[12],[2,\"\\n\"],[2,\"    \"],[10,\"a\"],[14,0,\"chrome\"],[14,6,\"https://www.google.com/chrome/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"Google Chrome\"],[13],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-brd-gray upper\"]],[[\"@route\"],[\"user.inbox\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"string.back\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "front/templates/profile/video/support.hbs"
    }
  });

  _exports.default = _default;
});