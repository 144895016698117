define("front/components/react-job-card/react-job-card", ["exports", "front/config/environment", "moment", "react-apps", "front/react-component"], function (_exports, _environment, _moment, _reactApps, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "+jYXRVoz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/components/react-job-card/react-job-card.hbs"
    }
  });

  var features = _environment.default.REGION.features;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    classNames: ['jobs--grid'],
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    gtm: Ember.inject.service(),
    appApi: Ember.inject.service(),
    fb: Ember.inject.service(),
    reactPortal: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;

      var jobs = this.jobs.map(function (job) {
        return _objectSpread(_objectSpread({}, job.serialize()), {}, {
          read: job.read
        });
      });
      (0, _reactApps.renderJobCards)(this.element, jobs, {
        appApi: this.appApi,
        env: _environment.default.environment,
        intl: this.intl,
        fb: this.fb,
        gtm: this.gtm,
        config: _environment.default,
        moment: _moment.default,
        features: features,
        currentSession: this.currentSession,
        transitionTo: function transitionTo(route, model) {
          if (model) {
            _this.router.transitionTo(route, model);
          } else {
            _this.router.transitionTo(route);
          }
        },
        generateRoute: function generateRoute(route, model) {
          if (model) {
            return _this.router.urlFor(route, model);
          } else {
            return _this.router.urlFor(route);
          }
        }
      });
    }
  }));

  _exports.default = _default;
});