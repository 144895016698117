define("front/serializers/jobapplication", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      eventDates: {
        embedded: 'always'
      },
      attachments: {
        serialize: 'ids'
      },
      questions: {
        embedded: 'always'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'applications' || payloadKey === 'application') {
        return this._super('jobapplication');
      } else {
        return this._super(payloadKey);
      }
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      data["application"] = this.serialize(record, options);
    }
  });

  _exports.default = _default;
});