define("front/routes/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    profile: Ember.computed.alias('currentSession.profile'),
    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('index');
      }

      return this.currentSession.load();
    },
    model: function model() {
      return this.profile;
    },
    actions: {
      refreshData: function refreshData() {
        // this.profile.reload().then(() => {
        //   this.refresh();
        // });
        this.refresh();
      },
      closeProfile: function closeProfile() {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});