define("front/controllers/application", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loading: false,
    profile: Ember.computed.alias('currentSession.profile'),
    loginManager: Ember.inject.service(),
    cookies: (0, _emberLocalStorage.storageFor)('cookies'),
    notificationManager: Ember.inject.service(),
    reactPortal: Ember.inject.service(),
    logout: function logout() {
      var _this = this;

      this.loginManager.logout(function () {
        _this.transitionToRoute('index');
      });
    }
  });

  _exports.default = _default;
});