define("front/templates/user/inbox/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q3OQA7HF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user/inbox/chat\",[],[[\"@application\",\"@refresh\",\"@reset\"],[[32,0,[\"model\"]],[32,0,[\"refresh\"]],[32,0,[\"reset\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front/templates/user/inbox/detail.hbs"
    }
  });

  _exports.default = _default;
});