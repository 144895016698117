define("front/templates/components/apply/incomplete-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z9O8fm8k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cover\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.job_apply.header\"],[[\"brand\"],[[35,0,[\"brand\",\"name\"]]]]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"profile-icon\"],[14,\"src\",\"/img/icons/profile-red.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[10,\"p\"],[12],[10,\"strong\"],[14,0,\"red\"],[12],[1,[30,[36,1],[\"components.job_apply.incomplete\"],[[\"completePercentage\"],[[35,2]]]]],[13],[13],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.job_apply.complete_profile\"],[[\"htmlSafe\",\"brandName\",\"threshold\"],[true,[35,0,[\"brand\",\"name\"]],[35,3]]]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[4,[38,4],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"blue\"],[4,[38,4],[[32,0],\"toggleUpdate\"],null],[12],[1,[30,[36,1],[\"components.job_apply.update_profile\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"job\",\"t\",\"completePercentage\",\"threshold\",\"action\"]}",
    "meta": {
      "moduleName": "front/templates/components/apply/incomplete-profile.hbs"
    }
  });

  _exports.default = _default;
});