define("front/templates/components/vi-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C7Y2CaPh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-10\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,[36,1],[\"companies_viphone.png\"],null]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-9 col-md-offset-1\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"companies.vi.h4\"],null]],[13],[2,\"\\n\\n    \"],[10,\"ul\"],[14,0,\"ol-list\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"em\"],[12],[2,\"1\"],[13],[2,\" \"],[1,[30,[36,0],[\"companies.vi.l1\"],null]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"em\"],[12],[2,\"2\"],[13],[2,\" \"],[1,[30,[36,0],[\"companies.vi.l2\"],null]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"em\"],[12],[2,\"3\"],[13],[2,\" \"],[1,[30,[36,0],[\"companies.vi.l3\"],null]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"em\"],[12],[2,\"4\"],[13],[2,\" \"],[1,[30,[36,0],[\"companies.vi.l4\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"text-center top-space\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-orange upper\"]],[[\"@route\"],[\"companies.videointerviews\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"companies.vi.more\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"region-asset\",\"hideBtn\",\"unless\"]}",
    "meta": {
      "moduleName": "front/templates/components/vi-features.hbs"
    }
  });

  _exports.default = _default;
});