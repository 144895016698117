define("front/templates/profile/video/disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBVYHt4G",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[2,\"BILUN Í VAFRA\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Því miður er ekki hægt að taka vídeóviðtal í vafra sem stendur.\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Þú getur tekið vídeóviðtalið í snjallsíma með Alfreð appinu.\"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"btn upper\"],[4,[38,2],[[32,0],[30,[36,1],[\"downloadAppModal\",[32,0]],null]],null],[12],[1,[30,[36,3],[\"string.dwl_app\"],null]],[13],[2,\"\\n\\n  \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"browsers\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,[36,4],[\"companies_viphone.jpg\"],null]],[14,\"alt\",\"\"],[14,5,\"width:500px;\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-brd-gray upper\"]],[[\"@route\"],[\"user.inbox\"]],[[\"default\"],[{\"statements\":[[2,\"OK\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"modals/download-app\",[],[[\"@toggle\"],[[30,[36,0],[\"downloadAppModal\",[32,0]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"toggle\",\"action\",\"t\",\"region-asset\",\"downloadAppModal\",\"if\"]}",
    "meta": {
      "moduleName": "front/templates/profile/video/disabled.hbs"
    }
  });

  _exports.default = _default;
});