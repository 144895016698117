define("front/components/apply/e-sign", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    apiManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['e-sign'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        if (_this.get('currentSession.profile') && _this.get('currentSession.profile.phonenumber')) {
          _this.set('msisdn', _this.get('currentSession.profile.phonenumber'));
        }

        (0, _jquery.default)('#phone-input').focus();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      clearInterval(this.interval);
    },
    poll: function poll(token) {
      var _this2 = this;

      this.apiManager.get('/profiles/me/verify/status?token=' + token).then(function (res) {
        if (res.status === 'WAITING') {
          return;
        }

        if (res.status === 'ERROR') {
          _this2.notifications.error(res.message);

          clearInterval(_this2.interval);

          _this2.set('verifying');
        }

        if (res.status === 'OK') {
          clearInterval(_this2.interval);

          _this2.profile.reload().then(function () {
            _this2.set('verifying');

            _this2.set('showEsign');
          });
        }
      }).catch(function () {
        clearInterval(_this2.interval);

        _this2.notifications.warning(_this2.intl.t('components.job_apply.esign.error'));

        _this2.set('verifying');

        Ember.run.schedule('afterRender', function () {
          var phoneInput = (0, _jquery.default)('#phone-input');
          phoneInput.val(_this2.msisdn);
          phoneInput.focus();
        });
      });
    },
    update: function update(unmasked) {
      Ember.set(this, 'msisdn', unmasked);
    },
    verify: function verify() {
      var _this3 = this;

      this.set('loading', true);
      this.apiManager.post('/profiles/me/verify', {
        phone: this.msisdn
      }).then(function (res) {
        _this3.set('controlCode', res.controlCode);

        _this3.set('verifying', true);

        _this3.interval = setInterval(function () {
          _this3.poll(res.token);

          _this3.set('intervalSet', true);
        }, 3000);
      }).catch(function (err) {
        _this3.set('controlCode');

        if (err && err.message) {
          _this3.notifications.error(err.message);
        }

        if (_this3.intervalSet) {
          clearInterval(_this3.interval);

          _this3.set('intervalSet');
        }
      }).finally(function () {
        return _this3.set('loading');
      });
    },
    keyPress: function keyPress(key) {
      if ((key.key === 'Enter' || key.keyCode === 13) && this.msisdn && this.msisdn.length === 7) {
        this.verify();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "update", [_dec], Object.getOwnPropertyDescriptor(_obj, "update"), _obj), _applyDecoratedDescriptor(_obj, "verify", [_dec2], Object.getOwnPropertyDescriptor(_obj, "verify"), _obj), _applyDecoratedDescriptor(_obj, "keyPress", [_dec3], Object.getOwnPropertyDescriptor(_obj, "keyPress"), _obj)), _obj)));

  _exports.default = _default;
});