define("front/routes/profile/jobapplications", ["exports", "ember-local-storage", "jquery"], function (_exports, _emberLocalStorage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    storage: (0, _emberLocalStorage.storageFor)('user-profile'),
    model: function model(_ref) {
      var id = _ref.id;
      return Ember.RSVP.hash({
        applications: this.store.query('jobapplication', {}),
        jobapplication: id ? this.store.findRecord('jobapplication', id) : null
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('profile').set('fullHeader');
      Ember.run.next(this, function () {
        if (!controller.id) {
          controller.set('id', controller.get('model.applications.firstObject.id'));
        }

        (0, _jquery.default)("#messages .scroll-y").animate({
          scrollTop: (0, _jquery.default)("#messages .messages").height()
        }, 200);
      });
    },
    resetController: function resetController(controller) {
      controller.set('cancelmessage');
      this.controllerFor('profile').set('fullHeader');
    },
    actions: {
      refreshData: function refreshData() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});