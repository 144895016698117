define("front/templates/companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KzHCb7bp",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"/tmp/broccoli-514huOA165cXfhD/out-709-colocated_template_processor/front/templates/companies.hbs\"]]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "front/templates/companies.hbs"
    }
  });

  _exports.default = _default;
});