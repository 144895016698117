define("front/controllers/category", ["exports", "moment", "front/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var region = _environment.default.REGION.region;

  var _default = Ember.Controller.extend({
    queryParams: ['search', 'adtype', 'tagId', 'summerjobs'],
    gtm: Ember.inject.service(),
    store: Ember.inject.service(),
    category: Ember.computed.alias('model.category'),
    jobs: Ember.computed.alias('model.jobs'),
    tag: Ember.computed.alias('model.tag'),
    todayJobs: Ember.computed.filter('jobs', function (job) {
      return (0, _moment.default)(job.published).isSame((0, _moment.default)(), 'day');
    }),
    yesterdayJobs: Ember.computed.filter('jobs', function (job) {
      return (0, _moment.default)(job.published).isSame((0, _moment.default)().subtract(1, 'day'), 'day');
    }),
    olderJobs: Ember.computed.filter('jobs', function (job) {
      return (0, _moment.default)(job.published).isBefore((0, _moment.default)().subtract(1, 'day'), 'day');
    }),
    categories: Ember.computed.filter('model.categories', function (category) {
      return category.count > 0;
    }),
    zips: Ember.computed.filterBy('model.areas', 'selected', true),
    zipids: Ember.computed('zips.length', function () {
      if (this.zips.length) {
        var subareaids = [];
        this.zips.forEach(function (a) {
          a.subAreas.forEach(function (sa) {
            return subareaids.push(sa.id);
          });
        });
        return subareaids;
      }

      return [];
    }),
    jobtypes: Ember.computed.filterBy('model.jobtypes', 'selected', true),
    jobtypeids: Ember.computed.mapBy('jobtypes', 'id'),
    selectedTags: Ember.computed('categories.@each.selectedTags.length', function () {
      var tags = [];
      this.categories.forEach(function (category) {
        if (category.selectedTags.length) {
          category.selectedTags.forEach(function (tag) {
            return tags.push(tag);
          });
        }
      });
      return tags;
    }),
    tagsids: Ember.computed.mapBy('selectedTags', 'id'),
    showSummerjobs: Ember.computed('model.jobtypes.@each.selected', function () {
      if (region === 'is' && (0, _moment.default)().month() >= 8) {
        // hide from august - december, this is 0-indexed
        return false;
      } // hide summerjobs button if summerjobs are already selected


      return !this.model.jobtypes.toArray().some(function (jt) {
        return jt.id === '3' && jt.selected;
      });
    }),
    showChristmasJobs: Ember.computed(function () {
      return region === 'is' && (0, _moment.default)().month() >= 10;
    }),
    actions: {
      search: function search() {
        this.set('search', this.query);
        this.gtm.trackJobSearch(this.query);
      },
      clear: function clear() {
        this.set('search');
        this.set('query');
      },
      clearTag: function clearTag() {
        if (this.tagId) {
          var tag = this.store.peekRecord('jobtag', this.tagId);
          tag.set('selected', false);
          this.set('tagId');
        }
      },
      openCategory: function openCategory(category) {
        this.transitionToRoute('category', category.id);
      },
      refresh: function refresh(slug) {
        if (slug) {
          this.transitionToRoute('category', slug);
        }

        this.set('tagId');
        this.send('refreshModel');
      }
    }
  });

  _exports.default = _default;
});