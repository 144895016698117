define("front/serializers/category", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'slug',
    attrs: {
      jobTags: {
        embedded: 'always'
      }
    } // normalizeResponse(store, primaryModelClass, payload) {
    //   // The backend returns list of all job tags for each category when categories are queried.
    //   // This causes single-job view to load all job tags for a split second when transitioning from job to index.
    //   // We fix the problem by removing jobTags from payload.
    //   // if (payload.categories) {
    //   //   payload.categories.map(c => {
    //   //     delete c.jobTags;
    //   //   });
    //   // }
    //   // The backend returns empty jobtags when a single category is queried. This replaces jobtags in a single-job view.
    //   // To fix it we just delete the jobtags property from the category response.
    //   if (!payload.category || !payload.category.jobTags) {
    //     return this._super(...arguments);
    //   }
    //
    //   delete payload.category.jobTags;
    //   return this._super(...arguments);
    // },

  });

  _exports.default = _default;
});