define("front/services/app-api", ["exports", "front/config/environment", "front/services/api-manager"], function (_exports, _environment, _apiManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GENERAL_NAMESPACE = _environment.default.GENERAL_NAMESPACE;

  var _default = _apiManager.default.extend({
    namespace: '/' + GENERAL_NAMESPACE
  });

  _exports.default = _default;
});