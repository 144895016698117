define("front/components/modals/profile-terms", ["exports", "front/components/modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    actions: {
      acceptTerms: function acceptTerms() {
        this.set('profile.acceptedterms', true);
        this.profile.save();
      },
      declineTerms: function declineTerms() {
        this.set('profile.acceptedterms', false);
        this.afterDecline();
      }
    }
  });

  _exports.default = _default;
});