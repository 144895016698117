define("front/templates/components/jobs/filter-tags-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gl9lCGya",
    "block": "{\"symbols\":[\"@selectedCategory\",\"@categories\",\"@tags\",\"@clearJobtags\",\"@refresh\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,1],[[32,3,[\"length\"]],\"active\"],null]]]],[4,[38,3],[[32,0],[30,[36,2],[\"filterModal\",[32,0]],null]],null],[12],[2,\"\\n  \"],[1,[30,[36,4],[\"components.job_filter.jobtags.button\"],null]],[2,\"\\n\"],[6,[37,1],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"_blue\"],[12],[2,\"(\"],[1,[32,3,[\"length\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"filterModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"jobs/filter-tags-modal\",[],[[\"@selectedCategory\",\"@categories\",\"@tags\",\"@clearJobtags\",\"@toggle\",\"@refresh\"],[[32,1],[32,2],[32,3],[32,4],[30,[36,0],[\"filterModal\",[32,0]],null],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"if\",\"toggle\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/components/jobs/filter-tags-button.hbs"
    }
  });

  _exports.default = _default;
});