define("front/services/company-profile-social", ["exports", "alfred-company-profile/services/company-profile-social"], function (_exports, _companyProfileSocial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _companyProfileSocial.default;
    }
  });
});