define("front/serializers/question", ["exports", "front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      options: {
        embedded: 'always'
      },
      questionType: {
        embedded: 'always'
      },
      optionIds: {
        serialize: 'ids'
      },
      optionId: {
        serialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});